import { FormField, FormFieldProps } from 'melp-design/components';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useEmployeeGroups } from 'store/employee-groups';

interface Props
  extends Omit<FormFieldProps<Array<{ id: string; name: string }>>, 'render'> {}

const EmployeeGroupsField = (props: Props) => {
  const [searchText, setSearchText] = useState('');
  const handleSearch = useDebouncedCallback((_: any, newValue: string) => {
    setSearchText(newValue);
  }, 300);

  const { data: employeeGroups } = useEmployeeGroups({ name: searchText });

  return (
    <FormField
      {...props}
      render={{
        type: 'autocomplete',
        props: {
          options:
            employeeGroups?.items.map((group) => ({
              id: group.id,
              name: group.name,
            })) ?? [],
          multiple: true,
          filterOptions: (x: any) => x,
          onInputChange: handleSearch,
          getOptionLabel: (option: any) => option.name,
          isOptionEqualToValue: (option: any, value: any) => {
            return option.id === value.id;
          },
        },
      }}
    />
  );
};

export default EmployeeGroupsField;
