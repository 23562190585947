import { z } from 'zod';
import { employeeGroupsFiltersSchema } from './employee-groups.schemas';

export const employeeInGroupStatuses = ['included', 'excluded'] as const;
export type EmployeeInGroupStatus = (typeof employeeInGroupStatuses)[number];

export interface EmployeeGroup {
  id: string;
  name: string;
  employeeCount: number;
  benefitCount: number;
  benefits: Array<{ id: string; name: string }>;
  isAllEmployeesGroup: boolean;
}

export type EmployeeGroupsFilters = z.infer<typeof employeeGroupsFiltersSchema>;
