import {
  IEmployee,
  IEmployeeExpense,
  IEmployeeWalletBenefit,
  IEmployeeWalletRecognitions,
} from 'types/income';
import { converters as tableConverters } from 'store/table';
import {
  Expense,
  convertExpenseSource,
  convertExpenseType,
} from 'store/expenses';
import { convertEmbeddedCustomCategories } from 'store/custom-categories';
import { loaders } from './employees.loaders';
import {
  Employee,
  EmployeeJobTitle,
  EmployeeWalletBenefit,
  EmployeeWalletRecognitions,
} from './employees.types';

export const convertEmployee = (employee: IEmployee): Employee => {
  return {
    id: employee.id,
    firstName: employee.firstName,
    lastName: employee.lastName,
    fullName: [employee.firstName, employee.lastName].filter(Boolean).join(' '),
    email: employee.email ?? '',
    phone: employee.phone ?? '',
    status: employee.employmentStatus,
    birthday: employee.birthDate || null,
    hiredAt: employee.employmentDate,
    isAppUser: employee.appUser,
    customCategories: convertEmbeddedCustomCategories(
      employee.customCategories ?? [],
    ),
  };
};

const convertEmployees = (employees: IEmployee[]): Employee[] => {
  return employees.map(convertEmployee);
};

const convertExpense = (expense: IEmployeeExpense): Expense => {
  return {
    id: expense.id,
    date: expense.date,
    description: expense.description,
    employee: null,
    categories: expense.itemCategories,
    amount: expense.expense,
    source: convertExpenseSource(
      // @ts-expect-error empty ids are filtered out
      expense.sourceBenefits?.filter((benefit) => !!benefit.id) ?? [],
      !!expense.recognitionReward,
    ),
    status: expense.status,
    type: convertExpenseType(expense.type),
  };
};

const convertExpenses = (expenses: IEmployeeExpense[]): Expense[] => {
  return expenses.map(convertExpense);
};

const convertEmployeeWalletBenefit = (
  benefit: IEmployeeWalletBenefit,
): EmployeeWalletBenefit => {
  return {
    id: benefit.id,
    name: benefit.name,
    status: benefit.status,
    assignmentStatus: benefit.assignmentStatus,
    investment: benefit.investmentAmount ?? 0,
    expenses: benefit.expenses,
    remaining: benefit.remaining,
  };
};

const convertEmployeeWalletBenefits = (
  benefits: IEmployeeWalletBenefit[],
): EmployeeWalletBenefit[] => {
  return benefits.map(convertEmployeeWalletBenefit);
};

const convertWalletRecognitions = (
  recognitions: IEmployeeWalletRecognitions,
): EmployeeWalletRecognitions => {
  return {
    availableCurrency: recognitions.availableCurrency,
    availablePoints: recognitions.availablePoints,
    spentCurrency: recognitions.spentCurrency,
    spentPoints: recognitions.spentPoints,
    totalCurrency: recognitions.totalCurrency,
    totalPoints: recognitions.totalPoints,
  };
};

const convertEmployeeJobTitle = (jobTitle: string): EmployeeJobTitle => {
  return {
    name: jobTitle,
  };
};

const convertEmployeeJobTitles = (jobTitles: string[]): EmployeeJobTitle[] => {
  return jobTitles.map(convertEmployeeJobTitle);
};

export const converters = {
  getEmployees: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getEmployees>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertEmployees(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getExpenses: ({ data }: Awaited<ReturnType<typeof loaders.getExpenses>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertExpenses(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getWalletBenefits: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getWalletBenefits>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertEmployeeWalletBenefits(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getWalletRecognitions: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getWalletRecognitions>>) => {
    return convertWalletRecognitions(data);
  },
  getJobTitles: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getJobTitles>>) => {
    return convertEmployeeJobTitles(data);
  },
};
