import { Modal, Table } from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { useParentCompany } from 'state/ParentCompany';
import { DateRange } from 'types/Common';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';
import { FC, useState } from 'react';
import {
  RecognitionRecipientGiversFilters,
  recognitionRecipientGiversFiltersSchema,
  useRecognitionRecipientGivers,
} from 'store/recognition-recipients';
import { formatCurrency } from 'utils/format';
import { APP } from 'config';
import { ModalProps } from 'store/modal';

interface Props extends ModalProps {
  employeeId: string;
  date?: DateRange;
}

export const RecipientGiversListModal: FC<Props> = ({
  closeModal,
  employeeId,
  date,
}) => {
  const { t, i18n } = useTranslation();
  const recognitionCurrency = usePointsLabel();
  const { parentCompany } = useParentCompany();
  const currency = parentCompany?.defaultCurrency;

  const [filters, setFilters] = useState<RecognitionRecipientGiversFilters>(
    recognitionRecipientGiversFiltersSchema.parse({
      date,
    }),
  );

  const { data: givers, isLoading } = useRecognitionRecipientGivers(
    employeeId,
    filters,
  );

  return (
    <Modal.Content
      title={t('recognition.giversListRecipientsDetails')}
      actions={[
        {
          variant: 'neutral-outline',
          onClick: () => closeModal({ action: 'CLOSE' }),
          label: t('common.cancel'),
        },
      ]}
    >
      <Table
        data={givers?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'name',
            header: t('entity_type.employee'),
            cell: ({ row: { original: giver } }) => giver.fullName,
          },
          {
            id: 'points',
            header: t('recognition.senderRecipientsDetailsListTotalPoints', {
              recognitionCurrency,
            }),
            cell: ({ row: { original: giver } }) => giver.points,
            meta: { align: 'right' },
          },
          {
            id: 'currencyValue',
            header: t('recognition.senderRecipientsDetailsListTotalAmount', {
              accountCurrency: currency,
            }),
            cell: ({ row: { original: giver } }) =>
              formatCurrency(i18n.language, giver.currencyValue, currency),
            meta: { align: 'right' },
          },
          {
            id: 'count',
            header: t('recognition.senderRecipientsDetailsListSentCount'),
            cell: ({ row: { original: giver } }) => giver.count,
            meta: { align: 'right' },
          },
        ]}
        pagination={
          givers && {
            count: givers.total,
            page: filters.page,
            pageSize: filters.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              setFilters((prev) => ({ ...prev, page, pageSize }));
            },
          }
        }
      />
    </Modal.Content>
  );
};
