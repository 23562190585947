import { sortFiltersSchema } from 'utils/schemas';
import { z } from 'zod';

export const employeeGroupsFiltersSchema = z
  .object({
    name: z.string().catch(''),
    employeeGroups: z.array(z.string()).catch([]),
    benefits: z.array(z.string()).catch([]),
    companies: z.array(z.string()).catch([]),
    departments: z.array(z.string()).catch([]),
  })
  .merge(sortFiltersSchema);
