import {
  Button,
  ListTotal,
  Table,
  TableToolbar,
  TableWrapper,
} from 'melp-design/components';
import { Divider, Stack } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  DateRangeFilter,
  FilterButton,
  FiltersInModal,
} from 'components/filters';
import TextFilter from 'components/filters/TextFilter';
import DownloadExcelButton from 'components/table/DownloadExcelButton/DownloadExcelButton';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';
import { useSearchParams } from 'utils/navigation';
import {
  recognitionRecipientsFiltersSchema,
  useExportToXls,
  useRecognitionRecipients,
} from 'store/recognition-recipients';
import { formatCurrency } from 'utils/format';
import { APP } from 'config';
import { useMe } from 'state/Administrators';
import { useModalContext } from 'store/modal';
import { RecipientsTabsSwitcher } from '../atoms';
import GenerateTopRecipientsNewsButton from './GenerateTopRecipientsNewsButton/GenerateTopRecipientsNewsButton';
import { RecipientGiversListModal } from './atoms';

export const RecognitionsRecipientsList = () => {
  const { t, i18n } = useTranslation();
  const pointsLabel = usePointsLabel();
  const { openModal } = useModalContext();
  const { me } = useMe();
  const currency = me?.parentCompany.defaultCurrency;
  const { searchParams: filters, navigate } = useSearchParams(
    recognitionRecipientsFiltersSchema,
  );

  const { data: recipients, isLoading } = useRecognitionRecipients(filters);

  const { mutate: exportToXls, isLoading: isExportingToXls } = useExportToXls();

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack gap={1}>
          <RecipientsTabsSwitcher />

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            <ListTotal total={recipients?.total} />

            <Stack direction="row" alignItems="center" gap={1}>
              <FiltersInModal
                value={filters}
                initialValues={recognitionRecipientsFiltersSchema.parse({})}
                onChange={(v) => navigate({ params: v })}
                keysToExtractFromTotalCalculation={['search']}
              >
                {({ value, initialValues, setFilter }) => (
                  <FilterButton
                    label={t('common.date')}
                    value={value.date}
                    initialValue={initialValues.date}
                    onChange={(v) => setFilter('date', v)}
                  >
                    {({ value, applyFilter, clearFilter, close }) => (
                      <DateRangeFilter
                        value={value}
                        onApply={applyFilter}
                        onClear={clearFilter}
                        onCancel={close}
                        suggestions={['week', 'month', 'year']}
                        withTimezone
                      />
                    )}
                  </FilterButton>
                )}
              </FiltersInModal>

              <TextFilter
                value={filters.search}
                onApplyFilter={(v) => navigate({ params: { search: v } })}
                hint={t('recognition.recipientsListFiltersSearchHint')}
              />

              <Divider orientation="vertical" variant="middle" flexItem />

              <DownloadExcelButton
                listLength={recipients?.total ?? 0}
                exportToExcel={() => exportToXls({ filters })}
                loading={isExportingToXls}
              />
              <Divider orientation="vertical" variant="middle" flexItem />

              <GenerateTopRecipientsNewsButton />
            </Stack>
          </Stack>
        </Stack>
      </TableToolbar>

      <Table
        data={recipients?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'name',
            header: t('entity_type.employee'),
            cell: ({ row: { original: recipient } }) => {
              return recipient.fullName;
            },
          },
          {
            id: 'points',
            header: t('recognition.recipientsListTotalReceived', {
              recognitionCurrency: pointsLabel,
            }),
            cell: ({ row: { original: recipient } }) => {
              return recipient.points;
            },
            meta: { sort: true, align: 'right' },
          },
          {
            id: 'currencyValue',
            header: t('recognition.recipientsListTotalReceivedCurrency', {
              accountCurrency: currency,
            }),
            cell: ({ row: { original: recipient } }) => {
              return formatCurrency(
                i18n.language,
                recipient.currencyValue,
                currency,
              );
            },
            meta: { sort: true, align: 'right' },
          },
          {
            id: 'count',
            header: t('recognition.recipientsListRecognitionCount'),
            cell: ({ row: { original: recipient } }) => {
              return recipient.count;
            },
            meta: { sort: true, align: 'right' },
          },
          {
            id: 'sendersDetails',
            header: t('recognition.recipientsListSendersDetails'),
            cell: ({ row: { original: recipient } }) => {
              return (
                <Button
                  label={t('recognition.giversListRecipientsDetailsLink')}
                  size="sm"
                  variant="neutral-outline"
                  onClick={() => {
                    openModal({
                      component: RecipientGiversListModal,
                      props: {
                        employeeId: recipient.id,
                        date: filters.date,
                      },
                      size: 'lg',
                    });
                  }}
                />
              );
            },
          },
          {
            id: 'lastAwardedAt',
            header: t('recognition.recipientsListLastActivity'),
            cell: ({ row: { original: recipient } }) => {
              return recipient.lastAwardedAt
                ? moment(recipient.lastAwardedAt).format('l')
                : '-';
            },
          },
        ]}
        sort={{
          value: filters.sort,
          onChange: (v) => navigate({ params: { sort: v } }),
        }}
        pagination={
          recipients && {
            count: recipients.total,
            page: recipients.page,
            pageSize: recipients.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
      />
    </TableWrapper>
  );
};
