import { MpFormOverridablesMap } from '@mp-react/form';

export interface UseFormUtils {
  overridables: Partial<MpFormOverridablesMap>;
  getFormFieldsName: (name: string, language?: string) => string;
  getFormKeys: (name: string) => string[];
}

type SupportedControls =
  | 'multiAutocomplete'
  | 'image'
  | 'checkbox'
  | 'dropdown'
  | 'attachment'
  | 'phone'
  | 'text'
  | 'numberWithComma'
  | 'select'
  | 'currency'
  | 'richText';

// Using any just as a workaround for non-extendable @mp-react/form control API.
export const CustomControlType: Record<SupportedControls, any> = {
  multiAutocomplete: 'multiAutocomplete',
  image: 'image',
  checkbox: 'checkbox',
  dropdown: 'dropdown',
  attachment: 'attachment',
  phone: 'phone',
  text: 'text',
  numberWithComma: 'numberWithComma',
  select: 'select',
  currency: 'currency',
  richText: 'richText',
};
