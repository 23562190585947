import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './recognition-recipients.loaders';
import { converters } from './recognition-recipients.converters';
import {
  RecognitionRecipientGiversFilters,
  RecognitionRecipientsFilters,
} from './recognition-recipients.types';

export const recognitionRecipientsKeys = queryKeyFactory(
  'recognition-recipients',
);

export const useRecognitionRecipients = (
  filters: Partial<RecognitionRecipientsFilters> = {},
) => {
  return useQuery({
    queryKey: recognitionRecipientsKeys.list(filters),
    queryFn: () => loaders.getRecipients({ filters }),
    select: converters.getRecipients,
  });
};

export const useRecognitionRecipientGivers = (
  employeeId: string,
  filters: Partial<RecognitionRecipientGiversFilters> = {},
) => {
  return useQuery({
    queryKey: recognitionRecipientsKeys.list(employeeId, filters),
    queryFn: () => loaders.getRecipientGivers({ employeeId, filters }),
    select: converters.getRecipientGivers,
    enabled: !!employeeId,
  });
};
