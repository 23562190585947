import { z } from 'zod';
import { orderBy } from 'lodash';
import { IEmployeesGroup } from 'types/income';
import { sortFiltersSchema } from 'utils/schemas';
import { loaders } from './employee-groups.loaders';
import { EmployeeGroup } from './employee-groups.types';

const convertEmployeeGroup = (
  employeesGroup: IEmployeesGroup,
): EmployeeGroup => {
  return {
    id: employeesGroup.id,
    name: employeesGroup.name,
    employeeCount: employeesGroup.employeeCount,
    benefitCount: employeesGroup.benefitCount,
    benefits: employeesGroup.benefits,
    isAllEmployeesGroup: employeesGroup.allEmployees,
  };
};

const convertEmployeeGroups = (
  employeesGroups: IEmployeesGroup[],
): EmployeeGroup[] => {
  return employeesGroups.map(convertEmployeeGroup);
};

export const converters = {
  getEmployeeGroups: (
    { data }: Awaited<ReturnType<typeof loaders.getEmployeeGroups>>,
    sort?: z.infer<typeof sortFiltersSchema>['sort'],
  ) => {
    const items = convertEmployeeGroups(data.data);

    return {
      items: sort ? orderBy(items, [sort.columnKey], [sort.order]) : items,
      total: items.length,
    };
  },
  getEmployeeGroup: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getEmployeeGroup>>) => {
    return convertEmployeeGroup(data);
  },
};
