import { converters as tableConverters } from 'store/table';
import { IRecognitionGiver, IRecognitionGiverRecipient } from 'types/income';
import { loaders } from './recognition-givers.loaders';
import {
  RecognitionGiver,
  RecognitionGiverRecipient,
} from './recognition-givers.types';

const convertGiver = (giver: IRecognitionGiver): RecognitionGiver => {
  return {
    id: giver.id,
    firstName: giver.firstName,
    lastName: giver.lastName,
    fullName: [giver.firstName, giver.lastName].filter(Boolean).join(' '),
    allAllowanceCurrency: giver.allAllowanceCurrency,
    allAllowancePoints: giver.allAllowancePoints,
    allowanceBalanceCurrency: giver.allowanceBalanceCurrency,
    allowanceBalancePoints: giver.allowanceBalancePoints,
    givenRecognitionCount: giver.givenRecognitionCount,
    givenRecognitionCurrency: giver.givenRecognitionCurrency,
    givenRecognitionPoints: giver.givenRecognitionPoints,
    participationPercentage: giver.participationPercentage,
    lastGivenRecognitionAt: giver.lastGivenRecognitionAt ?? null,
  };
};

const convertGivers = (givers: IRecognitionGiver[]): RecognitionGiver[] => {
  return givers.map(convertGiver);
};

const convertGiverRecipient = (
  recipient: IRecognitionGiverRecipient,
): RecognitionGiverRecipient => {
  return {
    id: recipient.id,
    firstName: recipient.firstName,
    lastName: recipient.lastName,
    fullName: [recipient.firstName, recipient.lastName]
      .filter(Boolean)
      .join(' '),
    count: recipient.count,
    currencyValue: recipient.currencyValue,
    points: recipient.points,
  };
};

const convertGiverRecipients = (
  recipients: IRecognitionGiverRecipient[],
): RecognitionGiverRecipient[] => {
  return recipients.map(convertGiverRecipient);
};

export const converters = {
  getGivers: ({ data }: Awaited<ReturnType<typeof loaders.getGivers>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertGivers(data.employees),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getGiverRecipients: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getGiverRecipients>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertGiverRecipients(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
};
