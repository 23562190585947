import { MpControlType, MpFormType } from '@mp-react/form';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { APP } from 'config';
import { formatCountry } from 'utils/format';
import { Matchers } from '../../constants/form/form';
import { TooltipControl } from '../../types/Common';

export const useCreateClientForm = (): MpFormType => {
  const { t, i18n } = useTranslation();

  const options = useMemo(() => {
    return APP.countries.alpha3.map((alpha3) => ({
      type: 'select' as any,
      key: alpha3,
      label: formatCountry(i18n.language, alpha3),
    }));
  }, [i18n.language]);

  const controls = useMemo(
    (): TooltipControl[] => [
      {
        type: 'dropdown' as any,
        key: 'companyCountry',
        label: t('benefits.country'),
        required: true,
        children: options,
        tooltip: 'tooltip.add_client_country',
      },
      {
        type: MpControlType.text,
        key: 'companyName',
        validations: {
          minLength: 1,
        },
        messages: {
          required: t('form.required'),
          pattern: t('form.pattern'),
        },
        label: t('companies.company_name'),
        required: true,
        tooltip: 'tooltip.add_client_company_name',
      },
      {
        type: MpControlType.text,
        key: 'companyCode',
        messages: {
          required: t('form.required'),
        },
        label: t('companies.company_code'),
        required: true,
        tooltip: 'tooltip.clients_company_code',
        placeholder: t('companies.company_code'),
      },
      {
        type: MpControlType.text,
        key: 'adminEmail',
        validations: {
          minLength: 2,
          pattern: String(Matchers.EMAIL).slice(1, -1),
        },
        messages: {
          required: t('form.required'),
          pattern: t('form.pattern'),
        },
        label: t('client.admin_email'),
        required: true,
        tooltip: 'tooltip.add_client_email',
      },
      {
        type: MpControlType.text,
        key: 'adminFirstName',
        validations: {
          minLength: 1,
        },
        messages: {
          required: t('form.required'),
        },
        label: t('client.admin_first_name'),
        required: true,
        tooltip: 'tooltip.add_client_first_name',
      },
      {
        type: MpControlType.text,
        key: 'adminLastName',
        validations: {
          minLength: 1,
        },
        messages: {
          required: t('form.required'),
        },
        label: t('client.admin_last_name'),
        required: true,
        tooltip: 'tooltip.add_client_last_name',
      },
      {
        type: 'phone' as MpControlType,
        key: 'adminPhone',
        messages: {
          required: t('form.required'),
          minLength: t('form.min_length'),
        },
        label: t('client.admin_phone'),
        required: true,
        tooltip: 'tooltip.add_client_phone',
      },
      {
        type: MpControlType.multiline,
        key: 'internalComment',
        label: t('common.internal_comment'),
        placeholder: t('common.comments'),
        tooltip: 'tooltip.add_client_internal_comment',
      },
    ],
    [options, t],
  );

  return {
    controls,
    size: 'small',
    layout: 'separated',
    variant: 'outlined',
  };
};
