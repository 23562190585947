import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './recognition-givers.loaders';
import { converters } from './recognition-givers.converters';
import {
  RecognitionGiverRecipientsFilters,
  RecognitionGiversFilters,
} from './recognition-givers.types';

export const recognitionGiversKeys = queryKeyFactory('recognition-givers');

export const useRecognitionGivers = (
  filters: Partial<RecognitionGiversFilters> = {},
) => {
  return useQuery({
    queryKey: recognitionGiversKeys.list(filters),
    queryFn: () => loaders.getGivers({ filters }),
    select: converters.getGivers,
  });
};

export const useRecognitionGiverRecipients = (
  employeeId: string,
  filters: Partial<RecognitionGiverRecipientsFilters> = {},
) => {
  return useQuery({
    queryKey: recognitionGiversKeys.list(employeeId, filters),
    queryFn: () => loaders.getGiverRecipients({ employeeId, filters }),
    select: converters.getGiverRecipients,
    enabled: !!employeeId,
  });
};
