import { FC } from 'react';
import {
  Link,
  ListPageHeader,
  ListTotal,
  Menu,
  Table,
  Typography,
} from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { Divider, Stack } from '@mui/material';
import { APP, ROUTES } from 'config';
import { sortBy } from 'lodash';
import {
  AdminSuppliersFilter,
  DateRangeFilter,
  FilterButton,
  FiltersInModal,
  SelectFilter,
} from 'components/filters';
import TextFilter from 'components/filters/TextFilter';
import { formatCountry, formatList } from 'utils/format';
import { shopItemCategories } from 'types/MarketplaceItems';
import DownloadExcelButton from 'components/table/DownloadExcelButton/DownloadExcelButton';
import { useSearchParams } from 'utils/navigation';
import {
  COLOR_BY_STATUS,
  adminShopItemsFiltersSchema,
  useAdminShopItems,
  useAdminShopItemsInfo,
  useExportShopItemsTemplate,
  useExportToXls,
} from 'store/admin-shop';
import { shopItemStatuses } from 'store/shop';
import { useModalContext } from 'store/modal';
import { CreateShopItemModal } from 'containers/admin';
// import { logsFiltersSchema } from 'store/logs';
import { ShopItemsImportModal } from './atoms';

export const AdminShopItemsList: FC = () => {
  const { t, i18n } = useTranslation();
  const { openModal } = useModalContext();

  const { searchParams: filters, navigate } = useSearchParams(
    adminShopItemsFiltersSchema,
  );
  // const { navigate: navigateToLogs } = useSearchParams(logsFiltersSchema);

  const { data: shopItems, isLoading } = useAdminShopItems(filters);
  const { data: info } = useAdminShopItemsInfo();

  const { mutate: exportToXls, isLoading: isExportingToXls } = useExportToXls();
  const {
    mutate: exportShopItemsTemplate,
    isLoading: isExportingShopItemsTemplate,
  } = useExportShopItemsTemplate();

  return (
    <>
      <ListPageHeader
        title={t('menu.items')}
        sideContent={
          <Menu
            label="Actions"
            items={[
              {
                label: t('marketplaceItems.addMarketplaceItem'),
                onClick: () => {
                  openModal({ component: CreateShopItemModal });
                },
              },
              {
                label: t('global.actions.bulk_update'),
                onClick: () => {
                  openModal({ component: ShopItemsImportModal });
                },
              },
              {
                label: t('global.actions.export_template'),
                onClick: () => {
                  exportShopItemsTemplate();
                },
                disabled: isExportingShopItemsTemplate,
              },
              // TODO: uncomment when BE fixes admin logs
              // {
              //   label: t('integrations.viewLogs'),
              //   onClick: () => {
              //     navigateToLogs({
              //       url: ROUTES.admin.logs,
              //       params: { actions: ['itemUpdatedFromExcel'] },
              //       replace: true,
              //     });
              //   },
              // },
            ]}
          />
        }
        toolbarContent={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={shopItems?.total} />
            <Stack direction="row" alignItems="center" gap="10px">
              <FiltersInModal
                value={filters}
                initialValues={adminShopItemsFiltersSchema.parse({})}
                onChange={(v) => navigate({ params: v })}
                keysToExtractFromTotalCalculation={['search']}
              >
                {({ value, initialValues, setFilter }) => (
                  <>
                    <FilterButton
                      label={t('marketplaceOrders.orderDate')}
                      value={value.createdAt}
                      initialValue={initialValues.createdAt}
                      onChange={(v) => setFilter('createdAt', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <DateRangeFilter
                          value={value}
                          onApply={applyFilter}
                          onClear={clearFilter}
                          onCancel={close}
                          suggestions={['week', 'month', 'year']}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('marketplaceItems.supplier')}
                      value={value.suppliers}
                      initialValue={initialValues.suppliers}
                      onChange={(v) => setFilter('suppliers', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <AdminSuppliersFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('benefits.country')}
                      value={value.countries}
                      initialValue={initialValues.countries}
                      onChange={(v) => setFilter('countries', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="countries"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          options={sortBy(
                            info?.countries.map((alpha3) => ({
                              key: alpha3,
                              label: formatCountry(i18n.language, alpha3),
                            })) ?? [],
                            ['label'],
                          )}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('common.city.label')}
                      value={value.cities}
                      initialValue={initialValues.cities}
                      onChange={(v) => setFilter('cities', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="cities"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          options={APP.cities.map((city) => ({
                            key: city,
                            label: city,
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('benefits.benefit_type')}
                      value={value.categories}
                      initialValue={initialValues.categories}
                      onChange={(v) => setFilter('categories', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="categories"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          options={shopItemCategories.map((category) => ({
                            key: category,
                            label: t(
                              `marketplaceItems.itemCategory-${category}`,
                            ),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('common.status')}
                      value={value.status}
                      initialValue={initialValues.status}
                      onChange={(v) => setFilter('status', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="status"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          searchable={false}
                          options={shopItemStatuses.map((status) => ({
                            key: status,
                            label: t(`status.${status}`),
                          }))}
                        />
                      )}
                    </FilterButton>
                  </>
                )}
              </FiltersInModal>

              <TextFilter
                value={filters.search}
                onApplyFilter={(v) => navigate({ params: { search: v } })}
                placeholder={t('common.search')}
              />

              <Divider orientation="vertical" variant="middle" flexItem />

              <DownloadExcelButton
                listLength={shopItems?.total ?? 0}
                exportToExcel={() => exportToXls({ filters })}
                loading={isExportingToXls}
              />
            </Stack>
          </Stack>
        }
      />

      <Table
        data={shopItems?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'id',
            header: t('marketplaceOrders.itemID'),
            cell: ({ row: { original: shopItem } }) => (
              <Link
                label={shopItem.sku}
                to={ROUTES.admin.shopItems.details.settings.replace(
                  ':id',
                  shopItem.id,
                )}
              />
            ),
          },
          {
            id: 'name',
            header: t('common.name'),
            cell: ({ row: { original: shopItem } }) => (
              <Link
                label={shopItem.name}
                to={ROUTES.admin.shopItems.details.settings.replace(
                  ':id',
                  shopItem.id,
                )}
              />
            ),
          },
          {
            id: 'supplier',
            header: t('marketplaceItems.supplier'),
            cell: ({ row: { original: shopItem } }) => (
              <Link
                label={shopItem.supplier.name}
                to={ROUTES.admin.suppliers.details.replace(
                  ':id',
                  shopItem.supplier.id,
                )}
              />
            ),
          },
          {
            id: 'country',
            header: t('marketplaceItems.countries'),
            cell: ({ row: { original: shopItem } }) =>
              formatList(
                i18n.language,
                shopItem.countries.map((country) =>
                  formatCountry(i18n.language, country),
                ),
              ),
          },
          {
            id: 'city',
            header: t('common.city.label'),
            cell: ({ row: { original: shopItem } }) =>
              formatList(i18n.language, shopItem.cities),
          },
          {
            id: 'category',
            header: t('marketplace.filtersItemCategory'),
            cell: ({ row: { original: shopItem } }) =>
              formatList(
                i18n.language,
                shopItem.categories.map((category) =>
                  t(`marketplaceItems.itemCategory-${category}`),
                ),
              ),
          },
          {
            id: 'sub_ordercount',
            header: t('menu.orders'),
            cell: ({ row: { original: shopItem } }) => shopItem.ordersCount,
            meta: { sort: true, align: 'right' },
          },

          {
            id: 'status',
            header: t('common.status'),
            cell: ({ row: { original: shopItem } }) => (
              <Typography color={COLOR_BY_STATUS[shopItem.status]}>
                {t(`status.${shopItem.status}`)}
              </Typography>
            ),
          },
        ]}
        sort={{
          value: filters.sort,
          onChange: (v) => navigate({ params: { sort: v } }),
        }}
        pagination={
          shopItems && {
            count: shopItems.total,
            page: filters.page,
            pageSize: filters.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
      />
    </>
  );
};
