import {
  Button,
  Table,
  TableToolbar,
  TableWrapper,
} from 'melp-design/components';
import { Divider, Stack } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  DateRangeFilter,
  FilterButton,
  FiltersInModal,
} from 'components/filters';
import TextFilter from 'components/filters/TextFilter';
import DownloadExcelButton from 'components/table/DownloadExcelButton/DownloadExcelButton';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';
import {
  recognitionGiversFiltersSchema,
  useExportToXls,
  useRecognitionGivers,
} from 'store/recognition-givers';
import { useSearchParams } from 'utils/navigation';
import { APP } from 'config';
import { formatPercents } from 'utils/format';
import { useModalContext } from 'store/modal';
import { GiverRecipientsListModal } from './atoms';

export const RecognitionsGiversList = () => {
  const { t, i18n } = useTranslation();
  const pointsLabel = usePointsLabel();
  const { openModal } = useModalContext();
  const { searchParams: filters, navigate } = useSearchParams(
    recognitionGiversFiltersSchema,
  );

  const { data: givers, isLoading } = useRecognitionGivers(filters);

  const { mutate: exportToXls, isLoading: isExportingToXls } = useExportToXls();

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack direction="row" justifyContent="end" gap="10px" p="10px">
          <FiltersInModal
            value={filters}
            initialValues={recognitionGiversFiltersSchema.parse({})}
            onChange={(v) => navigate({ params: v })}
            keysToExtractFromTotalCalculation={['search']}
          >
            {({ value, initialValues, setFilter }) => (
              <FilterButton
                label={t('common.date')}
                value={value.date}
                initialValue={initialValues.date}
                onChange={(v) => setFilter('date', v)}
              >
                {({ value, applyFilter, clearFilter, close }) => (
                  <DateRangeFilter
                    value={value}
                    onApply={applyFilter}
                    onClear={clearFilter}
                    onCancel={close}
                    suggestions={['week', 'month', 'year']}
                  />
                )}
              </FilterButton>
            )}
          </FiltersInModal>

          <TextFilter
            value={filters.search}
            onApplyFilter={(v) => navigate({ params: { search: v } })}
            hint={t('recognition.recipientsListFiltersSearchHint')}
          />

          <Divider orientation="vertical" variant="middle" flexItem />

          <DownloadExcelButton
            listLength={givers?.total ?? 0}
            exportToExcel={() => exportToXls({ filters })}
            loading={isExportingToXls}
          />
        </Stack>
      </TableToolbar>

      <Table
        data={givers?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'name',
            header: t('entity_type.employee'),
            cell: ({ row: { original: giver } }) => giver.fullName,
          },
          {
            id: 'givenRecognitionPoints',
            header: t('recognition.giversListTotalGiven', {
              recognitionCurrency: pointsLabel,
            }),
            cell: ({ row: { original: giver } }) =>
              giver.givenRecognitionPoints,
            meta: { sort: true, align: 'right' },
          },
          {
            id: 'participationPercentage',
            header: t('recognition.giversListParticipation'),
            cell: ({ row: { original: giver } }) =>
              formatPercents(
                i18n.language,
                giver.participationPercentage / 100,
              ),
            meta: { align: 'right' },
          },
          {
            id: 'givenRecognitionCount',
            header: t('recognition.giversListGivenCount'),
            cell: ({ row: { original: giver } }) => giver.givenRecognitionCount,
            meta: { align: 'right' },
          },
          {
            id: 'recipientsDetails',
            header: t('recognition.giversListRecipientsDetails'),
            cell: ({ row: { original: giver } }) => (
              <Button
                label={t('recognition.giversListRecipientsDetailsLink')}
                size="sm"
                variant="neutral-outline"
                onClick={() => {
                  openModal({
                    component: GiverRecipientsListModal,
                    props: {
                      employeeId: giver.id,
                      date: filters.date,
                    },
                    size: 'lg',
                  });
                }}
              />
            ),
          },
          {
            id: 'lastGivenRecognitionAt',
            header: t('recognition.giversListLastActivity'),
            cell: ({ row: { original: giver } }) =>
              giver.lastGivenRecognitionAt
                ? moment(giver.lastGivenRecognitionAt).format('l')
                : null,
          },
        ]}
        sort={{
          value: filters.sort,
          onChange: (v) => navigate({ params: { sort: v } }),
        }}
        pagination={
          givers && {
            count: givers.total,
            page: givers.page,
            pageSize: givers.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
      />
    </TableWrapper>
  );
};
