import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './employee-groups.loaders';
import { converters } from './employee-groups.converters';
import { EmployeeGroupsFilters } from './employee-groups.types';

export const employeeGroupsKeys = queryKeyFactory('employee-groups');

export const useEmployeeGroups = (
  filters: Partial<EmployeeGroupsFilters> = {},
) => {
  return useQuery({
    queryKey: employeeGroupsKeys.list(filters),
    queryFn: () => loaders.getEmployeeGroups({ filters }),
    select: (data) => converters.getEmployeeGroups(data, filters.sort),
  });
};

export const useEmployeeGroup = (employeeGroupId: string) => {
  return useQuery({
    queryKey: employeeGroupsKeys.detail(employeeGroupId),
    queryFn: () => loaders.getEmployeeGroup({ employeeGroupId }),
    select: converters.getEmployeeGroup,
  });
};
