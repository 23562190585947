import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { IEmployeesGroup } from 'types/income';
import { exportXlsxConfig } from 'utils/general';
import { EmployeeGroupsFilters } from './employee-groups.types';

const getFilters = (filters: Partial<EmployeeGroupsFilters>) => ({
  filter: {
    name: filters.name || undefined,
    groupIds: filters.employeeGroups,
    benefitPlanIds: filters.benefits,
    companyIds: filters.companies,
    departmentIds: filters.departments,
  },
});

interface GetEmployeeGroups {
  filters: Partial<EmployeeGroupsFilters>;
}

interface GetEmployeeGroup {
  employeeGroupId: string;
}

interface CreateEmployeeGroup {
  name: string;
}

interface UpdateEmployeeGroup {
  employeeGroupId: string;
  data: Partial<CreateEmployeeGroup>;
}

interface CopyEmployeeGroup {
  employeeGroupId: string;
  name: string;
}

interface DeleteEmployeeGroup {
  employeeGroupId: string;
}

interface ExportXls {
  filters: Partial<EmployeeGroupsFilters>;
}

export const loaders = {
  getEmployeeGroups: ({ filters }: GetEmployeeGroups) => {
    return api<{ data: IEmployeesGroup[] }>({
      url: Endpoints.clientAdmin.employeeGroups.rootv2,
      params: {
        ...getFilters(filters),
      },
    });
  },
  getEmployeeGroup: ({ employeeGroupId }: GetEmployeeGroup) => {
    return api<{ data: IEmployeesGroup[] }>({
      url: Endpoints.clientAdmin.employeeGroups.rootv2,
    }).then(({ data }) => {
      const selectedGroup = data.data.find(
        (group) => group.id === employeeGroupId,
      );

      if (!selectedGroup) throw new Error('Group not found');

      return { data: selectedGroup };
    });
  },
  createEmployeeGroup: ({ name }: CreateEmployeeGroup) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.employeeGroups.root,
      data: {
        name,
      },
      method: 'post',
    });
  },
  updateEmployeeGroup: ({ employeeGroupId, data }: UpdateEmployeeGroup) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.employeeGroups.byId.root.replace(
        ':employeeGroupId',
        employeeGroupId,
      ),
      data,
      method: 'patch',
    });
  },
  copyEmployeeGroup: ({ employeeGroupId, name }: CopyEmployeeGroup) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.employeeGroups.byId.copy.replace(
        ':employeeGroupId',
        employeeGroupId,
      ),
      data: { name },
      method: 'post',
    });
  },
  deleteEmployeeGroup: ({ employeeGroupId }: DeleteEmployeeGroup) => {
    return api({
      url: Endpoints.clientAdmin.employeeGroups.byId.root.replace(
        ':employeeGroupId',
        employeeGroupId,
      ),
      method: 'delete',
    });
  },
  exportXls: ({ filters }: ExportXls) => {
    const { ...restFilters } = getFilters(filters);
    return api({
      url: Endpoints.clientAdmin.employeeGroups.export.xls,
      params: {
        ...restFilters,
      },
      ...exportXlsxConfig,
    });
  },
};
