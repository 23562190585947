import { FC, PropsWithChildren } from 'react';
import { Dialog } from 'melp-design/components';
import { ModalContent } from './atoms';

export type ModalSize = 'md' | 'lg' | 'xl';

const WIDTH_BY_SIZE: Record<ModalSize, number> = {
  md: 450,
  lg: 650,
  xl: 900,
};

interface Props {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  size?: ModalSize;
}

interface ModalComposition {
  Content: typeof ModalContent;
}

export const Modal: FC<PropsWithChildren<Props>> & ModalComposition = ({
  id,
  isOpen,
  onClose,
  children,
  size = 'md',
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} id={id} width={WIDTH_BY_SIZE[size]}>
      {children}
    </Dialog>
  );
};

Modal.Content = ModalContent;
