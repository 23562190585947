import { FC } from 'react';
import { Stack } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { Button } from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import DeleteConfirmationDialog from 'components/dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { useRecognitionAllowanceRuleMutations } from 'state/Recognition';
import {
  RecognitionAllowanceRulesItem,
  UpdateRecognitionAllowanceRuleRequest,
} from 'types/Recognition';
import { useDialog } from 'utils/Dialog';
import { usePredefinedToasts } from 'utils/Toast';
import AllowanceRuleDialog from './AllowanceRuleDialog';

interface Props {
  ruleData: RecognitionAllowanceRulesItem;
}

const AllowanceRuleActions: FC<Props> = ({ ruleData }) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const allowanceRuleMutations = useRecognitionAllowanceRuleMutations(
    ruleData.id,
  );

  const updateDialog = useDialog();
  const handleUpdate = async (
    requestData: UpdateRecognitionAllowanceRuleRequest,
  ) => {
    await allowanceRuleMutations.update.execute(requestData);
    predefinedToasts.success.updated();
    updateDialog.closeDialog();
  };

  const removeConfirmation = useDialog();
  const handleRemove = async () => {
    await allowanceRuleMutations.remove.execute();
    predefinedToasts.success.deleted();
    removeConfirmation.closeDialog();
  };

  return (
    <>
      <Stack direction="row" gap="10px" justifyContent="end">
        <Button
          icon={Edit}
          label={t('common.edit')}
          variant="neutral-outline"
          size="sm"
          onClick={() => updateDialog.openDialog()}
        />

        <Button
          icon={Delete}
          label={t('actions.delete')}
          variant="neutral-outline"
          size="sm"
          onClick={() => {
            removeConfirmation.openDialog();
          }}
        />
      </Stack>

      <AllowanceRuleDialog
        open={updateDialog.open}
        onClose={updateDialog.closeDialog}
        title={t('recognition.updateAllowanceRule')}
        initialValues={ruleData}
        submitButtonTitle={t('common.save')}
        onSubmit={handleUpdate}
        loading={allowanceRuleMutations.update.loading}
      />
      <DeleteConfirmationDialog
        open={removeConfirmation.open}
        onCancel={removeConfirmation.closeDialog}
        onConfirm={handleRemove}
        loading={allowanceRuleMutations.remove.loading}
        content={t('recognition.removeAllowanceRuleWarning', {
          groups: ruleData.employeeGroups
            .map((employeeGroup) => employeeGroup.name)
            .join(', '),
        })}
      />
    </>
  );
};

export default AllowanceRuleActions;
