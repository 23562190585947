import { useMemo } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { MpControlProps } from '@mp-react/form';
import { useFile } from 'store/files';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/cross.svg';

interface IProps extends Partial<MpControlProps> {
  value?: string;
  removeFileHandler: () => void;
  name?: string;
}

export default function FileField(props: IProps) {
  const { value, size, removeFileHandler, name } = props;
  const id = useMemo(
    () =>
      value && typeof value === 'object'
        ? (value as { fileId: string }).fileId
        : value,
    [value],
  );
  const { data: file, isLoading } = useFile(id ?? '');

  if (!!value) if (!file || isLoading) return null;

  return (
    <TextField
      value={name ?? file?.name}
      variant="outlined"
      fullWidth
      disabled
      size={size}
      onClick={() => window.open(file?.url, '_blank')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                removeFileHandler();
              }}
              size="large"
            >
              <CrossIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
