import { FC } from 'react';
import {
  Link,
  ListPageHeader,
  Table,
  Typography,
} from 'melp-design/components';
import { Stack } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'utils/navigation';
import {
  adminClientDepositsFiltersSchema,
  adminDepositBalancesPerClientFiltersSchema,
  useAdminDepositBalancesPerClient,
} from 'store/admin-client-deposits';
import { formatCurrency } from 'utils/format';
import { ROUTES } from 'config';
import {
  DateFilter,
  FilterButton,
  FiltersInModal,
  ParentCompaniesFilter,
} from 'components/filters';
import { AdminClientDepositsActions } from 'containers/admin';
import { TabsSwitcher } from '../atoms';

export const AdminDepositBalancesListPerClient: FC = () => {
  const { t, i18n } = useTranslation();
  const { stringifyParams } = useSearchParams(adminClientDepositsFiltersSchema);

  const { searchParams: filters, navigate } = useSearchParams(
    adminDepositBalancesPerClientFiltersSchema,
  );

  const { data: deposits, isLoading } =
    useAdminDepositBalancesPerClient(filters);

  return (
    <>
      <ListPageHeader
        title={t('deposits.deposit_balance.title')}
        toolbarContent={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
            flexWrap="wrap"
          >
            <TabsSwitcher />

            <Stack direction="row" alignItems="center" gap="10px">
              <FiltersInModal
                value={filters}
                initialValues={adminDepositBalancesPerClientFiltersSchema.parse(
                  {},
                )}
                onChange={(v) => navigate({ params: v })}
              >
                {({ value, initialValues, setFilter }) => (
                  <>
                    <FilterButton
                      label={t('marketplaceOrders.client')}
                      value={value.clients}
                      initialValue={initialValues.clients}
                      onChange={(v) => setFilter('clients', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <ParentCompaniesFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('common.date')}
                      value={value.date}
                      initialValue={initialValues.date}
                      onChange={(v) => setFilter('date', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <DateFilter
                          value={value}
                          onApply={applyFilter}
                          onClear={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>
                  </>
                )}
              </FiltersInModal>
            </Stack>
          </Stack>
        }
      />

      <Table
        data={deposits?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'name',
            header: t('employees.company'),
            cell: ({ row: { original: balance } }) => {
              return (
                <Link
                  label={balance.client.name}
                  to={`${ROUTES.admin.clients.details.deposits.list.replace(
                    ':id',
                    balance.client.id,
                  )}${stringifyParams({
                    params: {
                      date: moment()
                        .subtract(1, 'month')
                        .endOf('month')
                        .toISOString(true)
                        .split('T')[0],
                    },
                  })}`}
                />
              );
            },
          },
          {
            id: 'remainingDeposit',
            header: t('clients.deposits.remaining.label'),
            cell: ({ row: { original: balance } }) => {
              return (
                <Typography
                  color={balance.remainingDeposit < 0 ? 'error' : 'textPrimary'}
                >
                  {formatCurrency(i18n.language, balance.remainingDeposit)}
                </Typography>
              );
            },
            meta: { align: 'right' },
          },
          {
            id: 'credit',
            header: t('common.credit.label'),
            cell: ({ row: { original: balance } }) => {
              return formatCurrency(i18n.language, balance.credit);
            },
            meta: { align: 'right' },
          },
          {
            id: 'lowDepositAlert',
            header: t('clients.deposits.low_deposit_alert.label'),
            cell: ({ row: { original: balance } }) => {
              return formatCurrency(i18n.language, balance.lowDepositAlert);
            },
            meta: { align: 'right' },
          },
          {
            id: 'actions',
            cell: ({ row: { original: balance } }) => {
              return (
                <AdminClientDepositsActions
                  clientId={balance.client.id}
                  companyId=""
                  credit={balance.credit}
                />
              );
            },
            meta: { align: 'right' },
          },
        ]}
      />
    </>
  );
};
