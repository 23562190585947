import { useMutation, useQueryClient } from '@tanstack/react-query';
import { converters as filesConverters } from 'store/files';
import { loaders } from './employee-groups.loaders';
import { employeeGroupsKeys } from './employee-groups.queries';

export const useCreateEmployeeGroup = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createEmployeeGroup, {
    onSuccess: () => {
      return queryClient.invalidateQueries(employeeGroupsKeys.all);
    },
  });
};

export const useUpdateEmployeeGroup = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateEmployeeGroup, {
    onSuccess: () => {
      return queryClient.invalidateQueries(employeeGroupsKeys.all);
    },
  });
};

export const useCopyEmployeeGroup = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.copyEmployeeGroup, {
    onSuccess: () => {
      return queryClient.invalidateQueries(employeeGroupsKeys.all);
    },
  });
};

export const useDeleteEmployeeGroup = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteEmployeeGroup, {
    onSuccess: () => {
      return queryClient.invalidateQueries(employeeGroupsKeys.all);
    },
  });
};

export const useExportToXls = () => {
  return useMutation(loaders.exportXls, {
    onSuccess: filesConverters.export,
  });
};
