import { getBorder } from 'constants/Style';
import {
  forwardRef,
  NamedExoticComponent,
  ReactElement,
  Ref,
  RefAttributes,
} from 'react';
import { OptionsItem, TextField } from 'melp-design/components';
import { Effects, SystemColors } from 'melp-design/style';
import { Checkbox } from '@mui/material';

interface BaseProps<Value> {
  label?: string;
  name?: string;
  disabled?: boolean;
  error?: string;
  multiple?: boolean;
  required?: boolean;
  options: Array<{ value: Value; label: string; disabled?: boolean }>;
}

interface SingleSelectProps<Value> extends BaseProps<Value> {
  multiple?: never;
  value?: Value;
  onChange?: (value: Value) => void;
}

interface MultipleSelectProps<Value> extends BaseProps<Value> {
  multiple: true;
  value?: Value[];
  onChange?: (value: Value[]) => void;
}

type Props<Value> = SingleSelectProps<Value> | MultipleSelectProps<Value>;

const SelectInputComponent = <Value extends string | number>(
  {
    label,
    name,
    options,
    disabled,
    multiple,
    required,
    error,
    value,
    onChange,
  }: Props<Value>,
  ref: Ref<HTMLInputElement>,
) => {
  return (
    <TextField
      select
      fullWidth
      label={label}
      name={name}
      errorMessage={error}
      required={required}
      disabled={disabled}
      value={value}
      // @ts-expect-error
      // from the docs: if multiple: true, e.target.value is an array
      // https://mui.com/material-ui/react-select/#multiple-select
      onChange={(e) => onChange?.(e.target.value)}
      SelectProps={{
        multiple,
        renderValue: (selected) => {
          return Array.isArray(selected)
            ? options
                .filter((option) => selected.includes(option.value))
                .map((option) => option.label)
                .join(', ')
            : options.find((option) => option.value === selected)?.label;
        },
        MenuProps: {
          sx: {
            '& .MuiPaper-root': {
              border: getBorder(SystemColors.grey[90]),
              boxShadow: Effects.smallElementsShadow,
              maxHeight: 400,
            },
            '& .MuiMenu-list': {
              padding: '5px',
            },
          },
        },
      }}
      ref={ref}
    >
      {options.map((option) => (
        <OptionsItem
          value={option.value}
          disabled={option.disabled}
          key={option.value}
        >
          {multiple ? (
            <Checkbox
              checked={(value?.indexOf(option.value) ?? 0) > -1}
              sx={{ padding: 0, mr: 1.5 }}
            />
          ) : null}
          {option.label}
        </OptionsItem>
      ))}
    </TextField>
  );
};

export const SelectInput = forwardRef(SelectInputComponent) as <
  Value extends string | number,
>(
  p: Props<Value> & RefAttributes<HTMLInputElement>,
) => ReactElement;

(SelectInput as NamedExoticComponent).displayName = 'SelectInput';
