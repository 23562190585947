import { SortAsc, SortDesc, Sort as SortIcon } from 'melp-design/icons';
import { Colors } from 'melp-design/style';
import {
  Box,
  ButtonBase,
  Checkbox,
  Table as MuiTable,
  Stack,
  SxProps,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Loader } from 'melp-design/components';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ReactNode, ChangeEvent } from 'react';
import { SortOrder } from '../../../components/filters/Types';
import { isDefined } from '../../../utils/isDefined';
import NoData from '../NoData/NoData';
import Pagination, { PaginationProps } from '../Pagination/Pagination';

interface SortValue {
  columnKey: string;
  order: SortOrder;
}

type Sort = SortValue | null;

type Alignment = 'left' | 'right' | 'center';
interface StructuredAlignment {
  /**
   * Table header cell alignment
   */
  header: Alignment;
  /**
   * Table data cell alignment
   */
  data: Alignment;
}
type ColumnAlignment = Alignment | Partial<StructuredAlignment>;

const getStructuredAlignment = (
  columnAlignment?: ColumnAlignment,
): StructuredAlignment => {
  const defaultAlignment = 'left';

  if (!columnAlignment || typeof columnAlignment === 'string') {
    return {
      header: columnAlignment ?? defaultAlignment,
      data: columnAlignment ?? defaultAlignment,
    };
  }

  return {
    header: columnAlignment.header ?? defaultAlignment,
    data: columnAlignment.data ?? defaultAlignment,
  };
};

const useStyles = makeStyles(() => ({
  paginationContainer: {
    paddingTop: 20,
  },
  inactiveRowCell: {
    color: 'rgba(30, 31, 35, 0.5) !important',
  },
  selectorCell: {
    '&.MuiTableCell-root:first-of-type': {
      padding: '10px 0 10px 10px',
      width: 48,
    },
    '&.MuiTableCell-head:first-of-type': {
      paddingTop: '4px',
      verticalAlign: 'top',
    },
  },
}));

interface ColumnType<T> {
  /**
   * Unique key of the column
   */
  key: string;
  /**
   * A renderer for the table cell
   *
   * @param value A value which was obtained from the data item using attribute "key"
   * @param record Row data
   *
   * @returns React node to display
   */
  render?: (value: any, record: T) => ReactNode;
  /**
   * A text to display for this column in the table header
   */
  title?: ReactNode;
  /**
   * Column width
   */
  width?: number | string;
  /**
   * Horizontal alignment
   */
  align?: ColumnAlignment;
  /**
   * Adjusts white-space handling of column data cells. Header cell is not
   * affected by this property.
   */
  whiteSpace?:
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-wrap'
    | 'pre-line'
    | 'break-spaces';
  /**
   * Prevents table cell click events from bubbling up. It is useful when one
   * wants to prevent accidental navigation when onRowClick is defined.
   */
  ignoreCellClick?: boolean;
  /**
   * Indicates whether sort capability is enabled for the column.
   */
  sort?: boolean;
  /**
   * Styles applied on a column
   */
  styles?: SxProps;
}

interface RowSelectionConfig {
  selectedRowKeys: string[];
  onChange: (selectedRowKeys: string[]) => void;
}

interface TableProps<T> {
  /**
   * Table columns configuration.
   */
  columns: ColumnType<T>[];
  /**
   * Data to display in table.
   */
  data?: T[];
  /**
   * A function to indicate whether row should be displayed as inactive.
   */
  isInactive?: (record: T) => boolean;
  setRowColor?: (record: T) => string | undefined;
  /**
   * Indicates whether table should be in loading state.
   */
  loading?: boolean;
  /**
   * Pagination configuration.
   */
  pagination?: PaginationProps;
  /**
   * Row identifier. Required to avoid React warnings.
   */
  rowKey: string;
  /**
   * Row selection config
   */
  rowSelection?: RowSelectionConfig;
  /**
   * Callback to execute when row is clicked
   */
  onRowClick?: (record: T) => void;
  /**
   * Current value of table sort
   */
  sort?: Sort;
  /**
   * Callback to execute when column sort changes
   */
  onSortChange?: (value: Sort) => void;
}

const Table = <T extends Record<string, any>>({
  isInactive,
  setRowColor,
  rowSelection,
  onRowClick,
  sort,
  ...props
}: TableProps<T>) => {
  const classes = useStyles();

  if (props.loading) {
    return (
      <Box className="TableLoader">
        <Loader />
      </Box>
    );
  }

  if (!props.data?.length) {
    return <NoData />;
  }

  const getCurrentRowsKeys = () => {
    return props.data?.map((item) => String(item[props.rowKey])) ?? [];
  };

  const handleSelectorClick = (
    event: ChangeEvent<HTMLInputElement>,
    rowKeys: string[],
  ) => {
    if (!rowSelection || !props.data) {
      return [];
    }

    const { selectedRowKeys: selected } = rowSelection;
    let newSelected: string[];

    if (event.target.checked) {
      newSelected = [...selected, ...rowKeys].filter(
        (v, i, a) => a.indexOf(v) === i,
      );
    } else {
      newSelected = selected.filter(
        (selectedKey) => !rowKeys.includes(selectedKey),
      );
    }

    rowSelection.onChange(newSelected);
  };

  const handleAllRowsSelectorClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (!rowSelection || !props.data) {
      return;
    }
    const rowKeys = getCurrentRowsKeys();
    handleSelectorClick(event, rowKeys);
  };

  const handleRowSelectorClick = (
    event: ChangeEvent<HTMLInputElement>,
    key: string,
  ) => {
    handleSelectorClick(event, [key]);
  };

  const isSelected = (key: string) => {
    if (!rowSelection) {
      return false;
    }
    return rowSelection.selectedRowKeys.indexOf(key) !== -1;
  };

  const getAllRowsSelectorState = () => {
    const numberOfSelectedRows = rowSelection?.selectedRowKeys.length ?? 0;
    if (numberOfSelectedRows === 0) {
      return {
        indeterminate: false,
        checked: false,
      };
    }

    return getCurrentRowsKeys().reduce(
      (acc, key, index, arr) => {
        if (isSelected(key)) {
          acc.indeterminate = true;
        } else {
          acc.checked = false;
        }
        if (index + 1 === arr.length && acc.checked) {
          acc.indeterminate = false;
        }
        return acc;
      },
      {
        indeterminate: false,
        checked: true,
      },
    );
  };

  const renderPaginator = () => {
    if (props.pagination) {
      return (
        <div className={classes.paginationContainer}>
          <Pagination {...props.pagination} />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div style={{ overflowX: 'auto' }}>
        <MuiTable>
          <TableHead>
            <TableRow>
              {!!rowSelection && (
                <TableCell className={classes.selectorCell}>
                  <Checkbox
                    {...getAllRowsSelectorState()}
                    onChange={handleAllRowsSelectorClick}
                    color="primary"
                  />
                </TableCell>
              )}
              {props.columns.map(({ title = '', ...column }) => {
                const handleColumnSort = () => {
                  const columnKey = column.key;
                  let newValue: Sort;
                  if (columnKey === sort?.columnKey) {
                    if (sort.order === 'desc') {
                      newValue = null;
                    } else {
                      newValue = { columnKey, order: 'desc' };
                    }
                  } else {
                    newValue = { columnKey, order: 'asc' };
                  }
                  props.onSortChange?.(newValue);
                };

                const getSortIconComponent = () => {
                  if (!sort || sort.columnKey !== column.key) {
                    return SortIcon;
                  }
                  return sort.order === 'asc' ? SortAsc : SortDesc;
                };

                const renderSortIcon = () => {
                  const commonProps = {
                    style: {
                      height: '10px',
                      width: 'auto',
                      transition: 'color 300ms ease-in-out',
                      flexShrink: 0,
                    },
                  };
                  const Icon = getSortIconComponent();
                  return <Icon {...commonProps} />;
                };

                const renderTitle = () => {
                  if (column.sort) {
                    return (
                      <ButtonBase
                        onClick={handleColumnSort}
                        disableRipple
                        sx={{
                          textAlign: 'inherit',
                          fontSize: 'inherit',
                          fontWeight: 'inherit',
                          lineHeight: 'inherit',
                          '&:hover svg': {
                            color: Colors.black,
                          },
                        }}
                      >
                        <Stack direction="row" alignItems="baseline" gap="10px">
                          {title}
                          {renderSortIcon()}
                        </Stack>
                      </ButtonBase>
                    );
                  }

                  return title;
                };

                return (
                  <TableCell
                    key={column.key}
                    sx={{
                      verticalAlign: 'top',
                      ...column.styles,
                      width: column.width,
                      textAlign: getStructuredAlignment(column.align).header,
                    }}
                  >
                    {renderTitle()}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((record) => {
              const rowKey = record[props.rowKey];
              const inactive = !!isInactive?.(record);
              const rowColor = setRowColor?.(record);
              const isItemSelected = isSelected(rowKey);

              return (
                <TableRow
                  key={rowKey}
                  hover
                  onClick={() => {
                    onRowClick?.(record);
                  }}
                  sx={{
                    cursor: onRowClick ? 'pointer' : 'default',
                    ...(rowColor
                      ? {
                          '&::before': {
                            background: rowColor,
                            opacity: 0.75,
                          },
                        }
                      : {}),
                  }}
                >
                  {!!rowSelection && (
                    <TableCell
                      className={classes.selectorCell}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(e) => handleRowSelectorClick(e, rowKey)}
                      />
                    </TableCell>
                  )}
                  {props.columns.map((column) => {
                    const value = record[column.key];
                    const renderValue = () => {
                      if (column.render) {
                        return column.render(value, record);
                      }

                      if (!isDefined(value)) {
                        return '';
                      }

                      if (Array.isArray(value)) {
                        return value.map((item) => String(item)).join(', ');
                      }

                      return String(value);
                    };
                    return (
                      <TableCell
                        key={column.key}
                        className={clsx(inactive && classes.inactiveRowCell)}
                        sx={{
                          ...column.styles,
                          textAlign: getStructuredAlignment(column.align).data,
                          whiteSpace: column.whiteSpace,
                        }}
                        onClick={(e) => {
                          if (column.ignoreCellClick) {
                            e.stopPropagation();
                          }
                        }}
                      >
                        {renderValue()}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </div>
      {renderPaginator()}
    </>
  );
};

export default Table;
