import { z } from 'zod';
import {
  dateRangeSchema,
  paginationFiltersSchema,
  sortFiltersSchema,
} from 'utils/schemas';

export const recognitionRecipientsFiltersSchema = z
  .object({
    search: z.string().catch(''),
    departments: z.array(z.string()).catch([]),
    date: dateRangeSchema,
  })
  .merge(paginationFiltersSchema)
  .merge(sortFiltersSchema);

export const recognitionRecipientGiversFiltersSchema = z
  .object({
    date: dateRangeSchema,
  })
  .merge(paginationFiltersSchema);
