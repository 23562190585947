import { FC, useState } from 'react';
import { useEmployeeJobTitles } from 'store/employees';
import { SelectFilter, SelectFilterProps } from 'components/filters';

type Props = Omit<
  SelectFilterProps<string, true>,
  'options' | 'loadingOptions' | 'asyncSearch' | 'onSearch' | 'multiple'
>;

export const JobTitlesFilter: FC<Props> = (props) => {
  const [search, setSearch] = useState('');

  const { data: jobTitles, isLoading } = useEmployeeJobTitles({
    search,
  });

  return (
    <SelectFilter
      {...props}
      options={jobTitles?.map((jobTitle) => ({
        key: jobTitle.name,
        label: jobTitle.name,
      }))}
      loadingOptions={isLoading}
      onSearch={setSearch}
      multiple
      asyncSearch
    />
  );
};
