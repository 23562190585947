import { FC } from 'react';
import {
  Button,
  Link,
  ListPageHeader,
  ListTotal,
  Table,
  Typography,
} from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { Divider, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { APP, ROUTES } from 'config';
import { calculateFiltersTotal } from 'utils/Filters';
import NumberRangeFilter from 'components/filters/NumberRangeFilter';
import {
  AdminSuppliersFilter,
  DateRangeFilter,
  FilterButton,
  Filters,
  SelectFilter,
} from 'components/filters';
import TextFilter from 'components/filters/TextFilter';
import moment from 'moment';
import { formatCountry, formatList } from 'utils/format';
import { shopItemCategories } from 'types/MarketplaceItems';
import DownloadExcelButton from 'components/table/DownloadExcelButton/DownloadExcelButton';
import { discountStatuses } from 'store/discounts';
import { useSearchParams } from 'utils/navigation';
import {
  COLOR_BY_PUBLISH_STATUS,
  COLOR_BY_STATUS,
  adminDiscountsFiltersSchema,
  discountPackages,
  discountPublishStatuses,
  useAdminDiscounts,
  useExportToXls,
} from 'store/admin-discounts';
import { DiscountActions } from '../atoms';

export const AdminDiscountsList: FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { searchParams: filters, navigate } = useSearchParams(
    adminDiscountsFiltersSchema,
  );

  const { data: discounts, isLoading: isDiscountsLoading } =
    useAdminDiscounts(filters);

  const { mutate: exportToXls, isLoading: isExportingToXls } = useExportToXls();

  return (
    <>
      <ListPageHeader
        title={t('discounts.title')}
        sideContent={
          <Button
            label={t('discounts.actions.create')}
            onClick={() => history.push(ROUTES.admin.discounts.create)}
          />
        }
        toolbarContent={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={discounts?.total} />
            <Stack direction="row" alignItems="center" gap="10px">
              <Filters
                value={filters}
                initialValues={adminDiscountsFiltersSchema.parse({})}
                onChange={(v) => navigate({ params: v })}
                showClearButton={!!calculateFiltersTotal(filters)}
              >
                {({ value, initialValues, setFilter }) => (
                  <>
                    <FilterButton
                      label={t('discounts.package.label')}
                      value={value.package}
                      initialValue={initialValues.package}
                      onChange={(v) => setFilter('package', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="package"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          searchable={false}
                          options={discountPackages.map((pack) => ({
                            key: pack,
                            label: t(`discounts.package.${pack}`),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('marketplaceItems.supplier')}
                      value={value.suppliers}
                      initialValue={initialValues.suppliers}
                      onChange={(v) => setFilter('suppliers', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <AdminSuppliersFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t(
                        'recognition.allowanceEmployeeOverviewListValidFrom',
                      )}
                      value={value.validAt}
                      initialValue={initialValues.validAt}
                      onChange={(v) => setFilter('validAt', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <DateRangeFilter
                          value={value}
                          onApply={applyFilter}
                          onClear={clearFilter}
                          onCancel={close}
                          suggestions={['week', 'month', 'quarter', 'year']}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('benefits.country')}
                      value={value.countries}
                      initialValue={initialValues.countries}
                      onChange={(v) => setFilter('countries', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="countries"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          options={APP.countries.alpha3.map((alpha3) => ({
                            key: alpha3,
                            label: formatCountry(i18n.language, alpha3),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('benefits.benefit_type')}
                      value={value.categories}
                      initialValue={initialValues.categories}
                      onChange={(v) => setFilter('categories', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="categories"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          options={shopItemCategories.map((category) => ({
                            key: category,
                            label: t(
                              `marketplaceItems.itemCategory-${category}`,
                            ),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('common.status')}
                      value={value.status}
                      initialValue={initialValues.status}
                      onChange={(v) => setFilter('status', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="status"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          searchable={false}
                          options={discountStatuses.map((status) => ({
                            key: status,
                            label: t(`status.${status}`),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('benefits.publish_status')}
                      value={value.publishStatus}
                      initialValue={initialValues.publishStatus}
                      onChange={(v) => setFilter('publishStatus', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="publishStatus"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          searchable={false}
                          options={discountPublishStatuses.map(
                            (publishStatus) => ({
                              key: publishStatus,
                              label: t(`status.${publishStatus}`),
                            }),
                          )}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('news.seen')}
                      value={value.viewsCount}
                      initialValue={initialValues.viewsCount}
                      onChange={(v) => setFilter('viewsCount', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <NumberRangeFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <TextFilter
                      value={value.search}
                      onApplyFilter={(v) => setFilter('search', v)}
                      placeholder={t('common.search')}
                    />
                  </>
                )}
              </Filters>

              <Divider orientation="vertical" variant="middle" flexItem />

              <DownloadExcelButton
                listLength={discounts?.total ?? 0}
                exportToExcel={() => exportToXls({ filters })}
                loading={isExportingToXls}
              />
            </Stack>
          </Stack>
        }
      />

      <Table
        data={discounts?.items ?? []}
        isLoading={isDiscountsLoading}
        columns={[
          {
            id: 'name',
            header: t('common.name'),
            cell: ({ row: { original: discount } }) => (
              <Link
                label={discount.name}
                to={ROUTES.admin.discounts.details.settings.replace(
                  ':id',
                  discount.id,
                )}
              />
            ),
          },
          {
            id: 'package',
            header: t('discounts.package.label'),
            cell: ({ row: { original: discount } }) =>
              formatList(
                i18n.language,
                discount.packages.map((pack) => t(`discounts.package.${pack}`)),
              ),
          },
          {
            id: 'supplier',
            header: t('marketplaceItems.supplier'),
            cell: ({ row: { original: discount } }) => (
              <Link
                label={discount.supplier.name}
                to={ROUTES.admin.suppliers.details.replace(
                  ':id',
                  discount.supplier.id,
                )}
              />
            ),
          },
          {
            id: 'priority',
            header: t('common.priority.label'),
            cell: ({ row: { original: discount } }) => discount.priority,
            meta: { sort: true, align: 'right' },
          },
          {
            id: 'validTo',
            header: t('recognition.allowanceEmployeeOverviewListValidTo'),
            cell: ({ row: { original: discount } }) =>
              discount.validTo ? (
                <Typography
                  color={
                    moment(discount.validTo).isBefore()
                      ? 'error'
                      : 'textPrimary'
                  }
                >
                  {moment(discount.validTo).format('l')}
                </Typography>
              ) : null,
            meta: { sort: true },
          },
          {
            id: 'status',
            header: t('common.status'),
            cell: ({ row: { original: discount } }) => (
              <Typography color={COLOR_BY_STATUS[discount.status]}>
                {t(`status.${discount.status}`)}
              </Typography>
            ),
          },
          {
            id: 'publishStatus',
            header: t('benefits.publish_status'),
            cell: ({ row: { original: discount } }) => (
              <Typography
                color={COLOR_BY_PUBLISH_STATUS[discount.publishStatus]}
              >
                {t(`status.${discount.publishStatus}`)}
              </Typography>
            ),
          },
          {
            id: 'favoritesCount',
            header: t('discounts.favorites.label'),
            cell: ({ row: { original: discount } }) => discount.favoritesCount,
            meta: { align: 'right' },
          },
          {
            id: 'viewsCount',
            header: t('news.seen'),
            cell: ({ row: { original: discount } }) => discount.viewsCount,
            meta: { sort: true, align: 'right' },
          },
          {
            id: 'uniqueViewsCount',
            header: t('discounts.uniqueViewsCount.label'),
            cell: ({ row: { original: discount } }) =>
              discount.uniqueViewsCount,
            meta: { align: 'right' },
          },
          {
            id: 'actions',
            cell: ({ row: { original: discount } }) => {
              return <DiscountActions id={discount.id} name={discount.name} />;
            },
            meta: { align: 'center' },
          },
        ]}
        sort={{
          value: filters.sort,
          onChange: (v) => navigate({ params: { sort: v } }),
        }}
        pagination={
          discounts && {
            count: discounts.total,
            page: filters.page,
            pageSize: filters.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
      />
    </>
  );
};
