import { converters as tableConverters } from 'store/table';
import {
  IRecognitionRecipient,
  IRecognitionRecipientGiver,
} from 'types/income';
import { loaders } from './recognition-recipients.loaders';
import {
  RecognitionRecipient,
  RecognitionRecipientGiver,
} from './recognition-recipients.types';

const convertRecipient = (
  recipient: IRecognitionRecipient,
): RecognitionRecipient => {
  return {
    id: recipient.id,
    firstName: recipient.firstName,
    lastName: recipient.lastName,
    fullName: [recipient.firstName, recipient.lastName]
      .filter(Boolean)
      .join(' '),
    points: recipient.points,
    count: recipient.count,
    currencyValue: recipient.currencyValue,
    lastAwardedAt: recipient.lastAwardedAt ?? null,
  };
};

const convertRecipients = (
  recipients: IRecognitionRecipient[],
): RecognitionRecipient[] => {
  return recipients.map(convertRecipient);
};

const convertRecipientGiver = (
  giver: IRecognitionRecipientGiver,
): RecognitionRecipientGiver => {
  return {
    id: giver.id,
    firstName: giver.firstName,
    lastName: giver.lastName,
    fullName: [giver.firstName, giver.lastName].filter(Boolean).join(' '),
    count: giver.count,
    currencyValue: giver.currencyValue,
    points: giver.points,
  };
};

const convertRecipientGivers = (
  givers: IRecognitionRecipientGiver[],
): RecognitionRecipientGiver[] => {
  return givers.map(convertRecipientGiver);
};

export const converters = {
  getRecipients: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getRecipients>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertRecipients(data.employees),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getRecipientGivers: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getRecipientGivers>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertRecipientGivers(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
};
