import fileDownloader from 'js-file-download';
import useSWR, { SWRResponse } from 'swr';
import { api, makeRequest } from '../api/api';
import { useLoading } from './Loading';
import { exportXlsxConfig } from './general';

type SupportedHttpMethods = 'post' | 'patch' | 'put' | 'delete';

interface MutationOptions<T, D> {
  onSuccess: (response: T) => void | Promise<any>;
  fetcher?: typeof makeRequest<T, D>;
}

/**
 * A hook for calling endpoints, which performs data mutation. It also tracks
 * a loading state. Mutations is invoked on demand.
 *
 * @param url Url to call
 * @param type HTTP method to use for mutation
 * @returns Object with mutation invoker and state data
 */
export const useCustomSwrMutation = <D = any, T = any, P = any>(
  url: string | ((urlParams?: P) => string),
  method: SupportedHttpMethods,
  options?: MutationOptions<T, D>,
) => {
  const mutationState = useLoading();
  const execute = async (data?: D, query: string = '', urlParams?: P) => {
    mutationState.startLoading();
    const finalUrl = typeof url === 'function' ? url(urlParams) : url + query;
    const fetcher = options?.fetcher ?? makeRequest;
    try {
      const response = await fetcher(method, finalUrl, data);
      await options?.onSuccess(response.data);
      return response.data;
    } finally {
      mutationState.stopLoading();
    }
  };
  return {
    execute,
    loading: mutationState.loading,
  };
};

export const useXLSExport = (url: string) => {
  const loadingState = useLoading();

  const exportToXLS = async () => {
    loadingState.startLoading();
    try {
      const response = await api({
        url,
        ...exportXlsxConfig,
      });
      const disposition = response?.headers['content-disposition'];
      const headersFilename = disposition?.split('filename=')?.[1] ?? 'export';
      fileDownloader(response.data, headersFilename);
      return;
    } finally {
      loadingState.stopLoading();
    }
  };

  return { exportToXLS, exporting: loadingState.loading };
};

interface BaseResponse<D, E> extends SWRResponse<D, E> {
  loading: boolean;
}

interface ResponseLoading<D, E> extends BaseResponse<D, E> {
  data: undefined;
  error: undefined;
  loading: true;
}

interface ResponseError<D, E> extends BaseResponse<D, E> {
  data: undefined;
  error: E;
  loading: false;
}

interface ResponseData<D, E> extends BaseResponse<D, E> {
  data: D;
  error: undefined;
  loading: false;
}

type UseGetResponse<D, E> =
  | BaseResponse<D, E>
  | ResponseLoading<D, E>
  | ResponseError<D, E>
  | ResponseData<D, E>;

/**
 * A thin wrapper over useSWR hook. Besides a default properties it provides a
 * response loading state flag and a better type inference.
 * @param url URL to call. Fetch is paused when url is set to null.
 * @returns Response data
 */
export const useGet = <D = any, E = any>(
  url: string | null,
  fetcher?: () => Promise<D>,
): UseGetResponse<D, E> => {
  // Setting last argument to undefined explicitly as typescript cannot
  // correctly infer overload
  const response = useSWR<D, E>(url, fetcher ?? null, undefined);

  return {
    ...response,
    loading: !response.data && !response.error,
  };
};
