import { Modal, Table } from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { useParentCompany } from 'state/ParentCompany';
import { DateRange } from 'types/Common';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';
import { FC, useState } from 'react';
import {
  RecognitionGiverRecipientsFilters,
  recognitionGiverRecipientsFiltersSchema,
  useRecognitionGiverRecipients,
} from 'store/recognition-givers';
import { formatCurrency } from 'utils/format';
import { APP } from 'config';
import { ModalProps } from 'store/modal';

interface Props extends ModalProps {
  employeeId: string;
  date?: DateRange;
}

export const GiverRecipientsListModal: FC<Props> = ({
  closeModal,
  employeeId,
  date,
}) => {
  const { t, i18n } = useTranslation();
  const recognitionCurrency = usePointsLabel();
  const { parentCompany } = useParentCompany();
  const currency = parentCompany?.defaultCurrency;

  const [filters, setFilters] = useState<RecognitionGiverRecipientsFilters>(
    recognitionGiverRecipientsFiltersSchema.parse({
      date,
    }),
  );

  const { data: recipients, isLoading } = useRecognitionGiverRecipients(
    employeeId,
    filters,
  );

  return (
    <Modal.Content
      title={t('recognition.recipientsListSendersDetails')}
      actions={[
        {
          variant: 'neutral-outline',
          onClick: () => closeModal({ action: 'CLOSE' }),
          label: t('common.cancel'),
        },
      ]}
    >
      <Table
        data={recipients?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'name',
            header: t('entity_type.employee'),
            cell: ({ row: { original: recipient } }) => recipient.fullName,
          },
          {
            id: 'points',
            header: t('recognition.recipientSendersDetailsListTotalGiven', {
              recognitionCurrency,
            }),
            cell: ({ row: { original: recipient } }) => recipient.points,
            meta: { align: 'right' },
          },
          {
            id: 'currencyValue',
            header: t('recognition.recipientSendersDetailsListTotalAmount', {
              accountCurrency: currency,
            }),
            cell: ({ row: { original: recipient } }) =>
              formatCurrency(i18n.language, recipient.currencyValue, currency),
            meta: { align: 'right' },
          },
          {
            id: 'count',
            header: t('recognition.recipientSendersDetailsListCount'),
            cell: ({ row: { original: recipient } }) => recipient.count,
            meta: { align: 'right' },
          },
        ]}
        pagination={
          recipients && {
            count: recipients.total,
            page: filters.page,
            pageSize: filters.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              setFilters((prev) => ({ ...prev, page, pageSize }));
            },
          }
        }
      />
    </Modal.Content>
  );
};
