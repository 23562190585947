import { z } from 'zod';
import { AdminRoles } from 'types/Administrators';
import { AssignmentStatus } from 'store/assignments';
import { arrayOfUnion } from 'utils/general';
import { PurchaseLimitPeriod, PurchaseLimitVariant } from 'store/internal-shop';
import { ShopItemStatus } from 'store/shop';
import { OrderStatus } from 'store/orders';
import { ExpirationNotificationMethod } from 'store/recognitions';
import { AppLanguage, Currency } from 'types/general';
import { logsFiltersSchema } from './logs.schemas';

export const logOwners = [
  'hrAdmin',
  'employee',
  'melpAdmin',
  'automation',
  'integration',
] as const;

export type LogOwner = (typeof logOwners)[number];

export type DataByAction =
  | {
      action: 'benefitCreated';
      data: {
        benefitName: string;
        benefitValue: number;
        isTrackBalanceEnabled: boolean;
        allowClaimReimbursement: boolean;
        activatedAt: string;
        deactivatedAt: string;
      };
    }
  | {
      action: 'benefitEdited';
      data: {
        benefitName: string;
        benefitValue: number;
        isTrackBalanceEnabled: boolean;
        allowClaimReimbursement: boolean;
        activatedAt: string;
        deactivatedAt: string;
      };
    }
  | {
      action: 'benefitToGroupStatusChange';
      data: {
        benefitName: string;
        groupName: string;
        status: AssignmentStatus;
        fromDate: string;
      };
    }
  | {
      action: 'benefitToEmployeeStatusChange';
      data: {
        benefitName: string;
        employeeFullName: string;
        status: AssignmentStatus;
        fromDate: string;
      };
    }
  | {
      action: 'benefitDeleted';
      data: { benefitName: string };
    }
  | {
      action: 'alternativeCreated';
      data: {
        alternativeName: string;
        isActive: boolean;
        deadlineInDays: number;
        deadlineFrom: string;
        deadlineTo: string;
      };
    }
  | {
      action: 'alternativeUpdated';
      data: {
        alternativeName: string;
        isActive: boolean;
        deadlineInDays: number;
        deadlineFrom: string;
        deadlineTo: string;
      };
    }
  | {
      action: 'alternativeAssignedToGroup';
      data: { alternativeName: string; groupName: string };
    }
  | {
      action: 'alternativeAssignedToEmployee';
      data: { alternativeName: string; employeeFullName: string };
    }
  | {
      action: 'alternativeRemovedFromGroup';
      data: { alternativeName: string; groupName: string };
    }
  | {
      action: 'alternativeRemovedFromEmployee';
      data: { alternativeName: string; employeeFullName: string };
    }
  | {
      action: 'alternativeDeleted';
      data: { alternativeName: string };
    }
  | {
      action: 'alternativeBenefitAssignedToEmployee';
      data: {
        alternativeName: string;
        benefitName: string;
        employeeFullName: string;
      };
    }
  | {
      action: 'alternativeSelectionLocked';
      data: {
        alternativeName: string;
        benefitName: string;
        employeeFullName: string;
      };
    }
  | {
      action: 'employeeCreated';
      data: {
        employeeFullName: string;
        email: string;
        phone: string;
        dateOfBirth: string;
        startDate: string;
        companyName: string;
        employmentDate: string;
        endDate: string;
        departmentName: string;
        jobTitle: string;
        internalEmployeeId: string;
      };
    }
  | {
      action: 'employeeUpdated';
      data: {
        employeeFullName: string;
        email: string;
        phone: string;
        dateOfBirth: string;
        startDate: string;
        companyName: string;
        employmentDate: string;
        endDate: string;
        departmentName: string;
        jobTitle: string;
        internalEmployeeId: string;
      };
    }
  | {
      action: 'employeeAddedToGroup';
      data: { employeeFullName: string; groupName: string; fromDate: string };
    }
  | {
      action: 'employeeRemovedFromGroup';
      data: { employeeFullName: string; groupName: string; fromDate: string };
    }
  | {
      action: 'employeeDeleted';
      data: { employeeFullName: string };
    }
  | {
      action: 'employeeGroupCreated';
      data: { groupName: string };
    }
  | {
      action: 'employeeGroupUpdated';
      data: { oldName: string; newName: string };
    }
  | {
      action: 'employeeGroupDeleted';
      data: { groupName: string };
    }
  | {
      action: 'recognitionSettingsUpdated';
      data: {
        useMoney: boolean;
        currencyToPoint: number;
        pointRewardPerRecognition: number;
      };
    }
  | {
      action: 'recognitionAllowanceCreated';
      data: { groupNames: string[]; points: number; frequency: string };
    }
  | {
      action: 'recognitionAllowanceUpdated';
      data: { groupNames: string[]; points: number; frequency: string };
    }
  | {
      action: 'recognitionAllowanceDeleted';
      data: { groupNames: string[]; points: number; frequency: string };
    }
  | {
      action: 'recognitionPointsImported';
      data: {};
    }
  | {
      action: 'recognitionUpdated';
      data: { employeeFullName: string; points: number; comment: string };
    }
  | {
      action: 'recognitionDeleted';
      data: { employeeFullName: string; points: number; comment: string };
    }
  | {
      action: 'expenseCreated';
      data: {
        employeeFullName: string;
        date: string;
        sourceBenefitsNames: string[];
        hasRecognitionReward: boolean;
        expense: number;
      };
    }
  | {
      action: 'expenseUpdated';
      data: {
        employeeFullName: string;
        date: string;
        sourceBenefitsNames: string[];
        hasRecognitionReward: boolean;
        expense: number;
      };
    }
  | {
      action: 'hrAdminCreated';
      data: { hrAdminFullName: string; email: string; phone: string };
    }
  | {
      action: 'hrAdminDeleted';
      data: { hrAdminFullName: string; email: string; phone: string };
    }
  | {
      action: 'hrAdminLoggedIn';
      data: { hrAdminFullName: string };
    }
  | {
      action: 'employeeLoggedIn';
      data: { employeeFullName: string; byEmail: boolean; byPhone: boolean };
    }
  | {
      action: 'employeeSelectedAlternativeBenefit';
      data: {
        employeeFullName: string;
        alternativeName: string;
        benefitName: string;
      };
    }
  | {
      action: 'employeePlacedMarketplaceOrder';
      data: { employeeFullName: string; marketplaceOrderNr: string };
    }
  | {
      action: 'melpChangedMarketplaceOrderStatus';
      data: {
        employeeFullName: string;
        marketplaceOrderNr: string;
        status: string;
      };
    }
  | {
      action: 'automationRuleSuccess';
      data: {
        ruleName: string;
        count: number;
      };
    }
  | {
      action: 'automationRuleError';
      data: {
        ruleName: string;
        error: string;
      };
    }
  | {
      action: 'internalItemCreated';
      data: {
        currency: Currency;
        name: string;
        prices: Array<{ price: number; title: string }>;
        purchaseLimit: number;
        purchaseLimitPeriod: PurchaseLimitPeriod;
        purchaseLimitPeriodType: PurchaseLimitVariant;
        status: ShopItemStatus;
        trackInventory: boolean;
      };
    }
  | {
      action: 'internalItemUpdated';
      data: {
        currency: Currency;
        name: string;
        prices: Array<{ price: number; title: string }>;
        purchaseLimit: number;
        purchaseLimitPeriod: PurchaseLimitPeriod;
        purchaseLimitPeriodType: PurchaseLimitVariant;
        status: ShopItemStatus;
        trackInventory: boolean;
      };
    }
  | {
      action: 'hrChangedMarketplaceOrderStatus';
      data: {
        employeeFullName: string;
        marketplaceOrderNr: string;
        status: OrderStatus;
      };
    }
  | {
      action: 'itemUpdatedFromExcel';
      data: {
        fileId: string;
        status: OrderStatus;
      };
    }
  | {
      action: 'parentCompanyGeneralSettingsChanged';
      data: {
        defaultLanguage: Uppercase<AppLanguage>;
        isEmailVisibleInternally: boolean;
        isPersonalCodeRequiredForLogin: boolean;
      };
    }
  | {
      action: 'surveyCreated';
      data: {
        surveyName: string;
      };
    }
  | {
      action: 'surveyEdited';
      data: {
        name: string;
        publishDateTime: string;
        endDateTime: string;
        anonymous: boolean;
        emailNotificationsEnabled: boolean;
        mobileNotificationsEnabled: boolean;
      };
    }
  | {
      action: 'surveyContentEdited';
      data: {
        surveyName: string;
      };
    }
  | {
      action: 'surveyDeadlineEdited';
      data: {
        name: string;
        endDateTime: string;
      };
    }
  | {
      action: 'surveyDeleted';
      data: {
        surveyName: string;
      };
    }
  | {
      action: 'recognitionExpirationUpdated';
      data: {
        hasPeriodicExpiration: boolean;
        expirationDay: number;
        expirationMonth: number;
        expirationNotificationDays: number;
        expirationNotification: ExpirationNotificationMethod[];
      };
    }
  | {
      action: 'recognitionExpired';
      data: {
        expirationDate: string;
      };
    };

export const logActions = arrayOfUnion<DataByAction['action']>()([
  'benefitCreated',
  'benefitEdited',
  'benefitToGroupStatusChange',
  'benefitToEmployeeStatusChange',
  'benefitDeleted',
  'alternativeCreated',
  'alternativeUpdated',
  'alternativeAssignedToGroup',
  'alternativeAssignedToEmployee',
  'alternativeRemovedFromGroup',
  'alternativeRemovedFromEmployee',
  'alternativeDeleted',
  'alternativeBenefitAssignedToEmployee',
  'alternativeSelectionLocked',
  'employeeCreated',
  'employeeUpdated',
  'employeeAddedToGroup',
  'employeeRemovedFromGroup',
  'employeeDeleted',
  'employeeGroupCreated',
  'employeeGroupDeleted',
  'employeeGroupUpdated',
  'recognitionSettingsUpdated',
  'recognitionAllowanceCreated',
  'recognitionAllowanceUpdated',
  'recognitionAllowanceDeleted',
  'recognitionPointsImported',
  'recognitionUpdated',
  'recognitionDeleted',
  'expenseCreated',
  'expenseUpdated',
  'hrAdminCreated',
  'hrAdminDeleted',
  'hrAdminLoggedIn',
  'employeeLoggedIn',
  'employeeSelectedAlternativeBenefit',
  'employeePlacedMarketplaceOrder',
  'melpChangedMarketplaceOrderStatus',
  'automationRuleSuccess',
  'automationRuleError',
  'internalItemCreated',
  'internalItemUpdated',
  'hrChangedMarketplaceOrderStatus',
  'itemUpdatedFromExcel',
  'parentCompanyGeneralSettingsChanged',
  'surveyCreated',
  'surveyEdited',
  'surveyContentEdited',
  'surveyDeadlineEdited',
  'surveyDeleted',
  'recognitionExpirationUpdated',
  'recognitionExpired',
] as const);

type BaseLog = {
  id: string;
  employee: { id: string; name: string; email: string } | null;
  admin: {
    id: string;
    name: string;
    email: string;
    role: AdminRoles;
  } | null;
  owner: {
    id: string;
    name: string;
    role: LogOwner;
  } | null;
  createdAt: string;
};

export type Log = BaseLog & DataByAction;

export type LogsFilters = z.infer<typeof logsFiltersSchema>;
