import { FC, useEffect, useMemo } from 'react';
import {
  Alert,
  Button,
  CheckboxGroupInput,
  DataLossPrompt,
  Footer,
  Loader,
  MultiLanguageInputContainer,
  NothingFoundAlert,
  NumberInput,
  Panel,
  RichTextInput,
  SelectInput,
  SwitchInput,
  Typography,
} from 'melp-design/components';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ExpirationNotificationMethod,
  RecognitionLimit,
  RecognitionPoint,
  expirationNotificationMethods,
  recognitionLimits,
  recognitionPoints,
  useRecognitionsSettings,
  useUpdateRecognitionsSettings,
} from 'store/recognitions';
import { useMe } from 'state/Administrators';
import { usePredefinedToasts } from 'utils/Toast';
import { formatCurrency, formatLanguage, formatMonth } from 'utils/format';
import { APP } from 'config';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form-latest';
import { ContentLanguage, FrequencyUnit, frequencyUnits } from 'types/general';
import { Translations } from 'types/Common';
import { useCompanyLanguages } from 'state/Administrators';
import { getDaysInMonth } from 'utils/general';

interface Values {
  pointRewardPerRecognition: number;
  pointName: RecognitionPoint;
  currencyToPoint: number;
  useMoney: boolean;
  restrictionsApply: boolean;
  limit: RecognitionLimit;
  limitValue: number;
  limitPeriod: FrequencyUnit | 'none';
  languages: Array<Uppercase<ContentLanguage>>;
  expirationEnabled: boolean;
  expirationDay: number;
  expirationMonth: number;
  expirationNotificationInDays: number;
  expirationNotification: ExpirationNotificationMethod[];
  translations: Translations<{ description: string }>;
}

const getInitialValues = (
  defaultLanguage: Uppercase<ContentLanguage>,
): Values => ({
  pointName: 'points',
  useMoney: false,
  currencyToPoint: 1,
  pointRewardPerRecognition: 1,
  restrictionsApply: false,
  limit: 'perEmployee',
  limitValue: 1,
  limitPeriod: 'daily',
  expirationEnabled: false,
  expirationDay: 1,
  expirationMonth: 1,
  expirationNotificationInDays: 0,
  expirationNotification: [],
  languages: [defaultLanguage],
  translations: [{ language: defaultLanguage, description: '' }],
});

export const RecognitionsSettings: FC = () => {
  const { t, i18n } = useTranslation();
  const predefinedToasts = usePredefinedToasts();
  const { defaultLanguage, supportedLanguages } = useCompanyLanguages();

  const INITIAL_VALUES = useMemo(
    () => getInitialValues(defaultLanguage),
    [defaultLanguage],
  );

  const { me, isFeatureEnabled } = useMe();
  const { data: settings, isLoading } = useRecognitionsSettings();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, dirtyFields },
  } = useForm({ defaultValues: INITIAL_VALUES });
  const { fields: translationsFields, replace: replaceTranslations } =
    useFieldArray({ name: 'translations', control });

  useEffect(() => {
    if (settings) {
      reset({
        pointName: settings.pointName,
        useMoney: settings.useMoney,
        currencyToPoint: settings.currencyToPoint,
        pointRewardPerRecognition: settings.pointRewardPerRecognition,
        restrictionsApply: settings.limit === 'none' ? false : true,
        expirationEnabled: settings.expirationEnabled,
        expirationDay: settings.expirationDay ?? 1,
        expirationMonth: settings.expirationMonth ?? 1,
        expirationNotificationInDays: settings.expirationNotificationInDays,
        expirationNotification: settings.expirationNotification,
        ...(settings.limit === 'none'
          ? {
              limit: INITIAL_VALUES.limit,
              limitValue: INITIAL_VALUES.limitValue,
              limitPeriod: INITIAL_VALUES.limitPeriod,
            }
          : {
              limit: settings.limit,
              limitValue: settings.limitValue,
              limitPeriod: settings.limitPeriod,
            }),
        ...(settings.translations.length
          ? {
              languages: settings.translations.map((trans) => trans.language),
              translations: settings.translations,
            }
          : {
              languages: INITIAL_VALUES.languages,
              translations: INITIAL_VALUES.translations,
            }),
      });
    }
  }, [INITIAL_VALUES, reset, settings]);

  const { mutate: updateSettings, isLoading: isUpdating } =
    useUpdateRecognitionsSettings();

  const onSubmit: SubmitHandler<Values> = (values) => {
    updateSettings(
      {
        useMoney: values.useMoney,
        pointName: values.pointName,
        currencyToPoint: values.currencyToPoint,
        pointRewardPerRecognition: values.pointRewardPerRecognition,
        expirationEnabled: values.expirationEnabled,
        expirationDay: values.expirationDay,
        expirationMonth: values.expirationMonth,
        expirationNotificationInDays: values.expirationNotificationInDays,
        expirationNotification: values.expirationNotification,
        translations: values.translations,
        ...(values.restrictionsApply
          ? {
              limit: values.limit,
              limitValue: values.limitValue,
              limitPeriod: values.limitPeriod,
            }
          : {
              limit: 'none',
              limitValue: 0,
              limitPeriod: 'none',
            }),
      },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
        },
      },
    );
  };

  if (isLoading) return <Loader />;
  if (!settings) return <NothingFoundAlert />;

  const isDirty = !!Object.keys(dirtyFields).length;

  const [
    useMoney,
    restrictionsApply,
    expirationEnabled,
    expirationMonth,
    languages,
    translations,
  ] = watch([
    'useMoney',
    'restrictionsApply',
    'expirationEnabled',
    'expirationMonth',
    'languages',
    'translations',
  ]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Panel title={t('common.settings')}>
            <Stack spacing={2}>
              <Controller
                name="useMoney"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => {
                  return (
                    <SwitchInput
                      label={t('recognition.form.useMoney.label')}
                      name={name}
                      value={value}
                      onChange={onChange}
                      ref={ref}
                    />
                  );
                }}
              />
              {!isFeatureEnabled('marketplace') &&
              isFeatureEnabled('recognition') &&
              useMoney ? (
                <Alert severity="warning">
                  {t('recognition.form.useMoney.info', {
                    email: APP.email.info,
                  })}
                </Alert>
              ) : null}
              {!useMoney ? (
                <Alert severity="warning">
                  {t('recognition.form.useMoney.info2')}
                </Alert>
              ) : null}
              <Box>
                <Typography variant="p2" color="textSecondary" mb={1}>
                  {t('recognition.exchangeRate')}
                </Typography>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Stack
                    direction="row"
                    sx={{
                      '& > *:first-of-type': {
                        width: '150px',
                        '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':
                          {
                            borderTopRightRadius: '0',
                            borderBottomRightRadius: '0',
                          },
                      },
                      '& > *:nth-of-type(2)': {
                        width: '150px',
                        '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':
                          {
                            borderTopLeftRadius: '0',
                            borderBottomLeftRadius: '0',
                            borderLeft: '0',
                          },
                      },
                    }}
                  >
                    <Controller
                      name="currencyToPoint"
                      control={control}
                      rules={{
                        min: {
                          value: 1,
                          message: t('common.minValueValidationError', {
                            min: 1,
                          }),
                        },
                      }}
                      render={({ field: { name, value, onChange, ref } }) => (
                        <NumberInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          errorMessage={errors.currencyToPoint?.message}
                          type="integer"
                          required
                          ref={ref}
                          key={value}
                        />
                      )}
                    />

                    <Controller
                      name="pointName"
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => {
                        return (
                          <SelectInput
                            name={name}
                            value={value}
                            onChange={onChange}
                            options={
                              recognitionPoints.map((type) => ({
                                label: t(`recognition.pointNames-${type}`),
                                value: type,
                              })) ?? []
                            }
                            required
                            ref={ref}
                          />
                        );
                      }}
                    />
                  </Stack>
                  <Typography variant="p1">{`= ${formatCurrency(
                    i18n.language,
                    100,
                    me?.parentCompany.defaultCurrency,
                  )}`}</Typography>
                </Stack>
              </Box>
              <Controller
                name="pointRewardPerRecognition"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <NumberInput
                    label={t('recognition.pointRewardPerRecognition')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    ref={ref}
                    key={value}
                  />
                )}
              />
              <Controller
                name="restrictionsApply"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => {
                  return (
                    <SwitchInput
                      label={t('recognitions.limit.label')}
                      name={name}
                      value={value}
                      onChange={onChange}
                      ref={ref}
                    />
                  );
                }}
              />
              {restrictionsApply ? (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <Typography sx={{ whiteSpace: 'nowrap', mr: 2 }}>
                    {t('recognitions.limit.employee_to')}
                  </Typography>

                  <Controller
                    name="limit"
                    control={control}
                    render={({ field: { name, value, onChange, ref } }) => {
                      return (
                        <SelectInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          options={recognitionLimits
                            .filter((limit) => limit !== 'none')
                            .map((limit) => ({
                              label: t(`recognitions.limit.${limit}`),
                              value: limit,
                            }))}
                          required
                          ref={ref}
                        />
                      );
                    }}
                  />

                  <Controller
                    name="limitValue"
                    control={control}
                    render={({ field: { name, value, onChange, ref } }) => {
                      return (
                        <NumberInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          required
                          sx={{ minWidth: 75 }}
                          key={value}
                          ref={ref}
                        />
                      );
                    }}
                  />

                  <Controller
                    name="limitPeriod"
                    control={control}
                    render={({ field: { name, value, onChange, ref } }) => {
                      return (
                        <SelectInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          options={frequencyUnits
                            .filter(
                              (period) =>
                                !['hourly', 'lifetime'].includes(period),
                            )
                            .map((period) => ({
                              label: t(
                                `recognition.allowanceRuleFrequency-${period}`,
                              ),
                              value: period,
                            }))}
                          required
                          ref={ref}
                        />
                      );
                    }}
                  />
                </Stack>
              ) : null}
            </Stack>
          </Panel>

          <Panel title={t('recognitions.expiration.title')}>
            <Stack gap={2}>
              <Controller
                name="expirationEnabled"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => {
                  return (
                    <SwitchInput
                      label={t('recognitions.expirationEnabled.label')}
                      name={name}
                      value={value}
                      onChange={onChange}
                      ref={ref}
                    />
                  );
                }}
              />

              {expirationEnabled ? (
                <>
                  <Typography sx={{ mr: 2 }}>
                    {t('recognitions.expiresOn.label')}
                  </Typography>

                  <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Controller
                      name="expirationMonth"
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => {
                        return (
                          <SelectInput
                            name={name}
                            value={value}
                            onChange={(v) => {
                              onChange(v);
                              setValue(
                                'expirationDay',
                                INITIAL_VALUES.expirationDay,
                              );
                            }}
                            options={[...Array(12).keys()].map((key) => ({
                              label: formatMonth(i18n.language, key),
                              value: key + 1,
                            }))}
                            required
                            ref={ref}
                          />
                        );
                      }}
                    />

                    <Controller
                      name="expirationDay"
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => {
                        return (
                          <SelectInput
                            name={name}
                            value={value}
                            onChange={onChange}
                            options={[
                              ...Array(getDaysInMonth(expirationMonth)).keys(),
                            ].map((month) => ({
                              label: String(month + 1),
                              value: month + 1,
                            }))}
                            required
                            ref={ref}
                          />
                        );
                      }}
                    />
                  </Stack>

                  <Controller
                    name="expirationNotificationInDays"
                    control={control}
                    rules={{
                      min: {
                        value: 0,
                        message: t('common.minValueValidationError', {
                          min: 0,
                        }),
                      },
                    }}
                    render={({ field: { name, value, onChange, ref } }) => (
                      <NumberInput
                        label={t(
                          'recognitions.expirationNotificationInDays.label',
                        )}
                        name={name}
                        value={value}
                        onChange={onChange}
                        errorMessage={
                          errors.expirationNotificationInDays?.message
                        }
                        type="integer"
                        required
                        sx={{ maxWidth: '50%' }}
                        ref={ref}
                        key={value}
                      />
                    )}
                  />

                  <Controller
                    name="expirationNotification"
                    control={control}
                    render={({ field: { name, value, onChange, ref } }) => {
                      return (
                        <CheckboxGroupInput
                          label={t('recognitions.expirationNotification.label')}
                          items={expirationNotificationMethods.map(
                            (method) => ({
                              label: t(`news.send_method.${method}`),
                              value: method,
                            }),
                          )}
                          name={name}
                          value={value}
                          onChange={onChange}
                          ref={ref}
                        />
                      );
                    }}
                  />
                </>
              ) : null}
            </Stack>
          </Panel>

          <Panel title={t('common.rules.title')}>
            <Stack gap={2}>
              <Controller
                name="languages"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => {
                  return (
                    <SelectInput
                      label={t('companies.supported_languages')}
                      name={name}
                      value={value}
                      onChange={(value) => {
                        const selectedLanguages = value as Array<
                          Uppercase<ContentLanguage>
                        >;
                        replaceTranslations(
                          selectedLanguages.map(
                            (language) =>
                              translations.find(
                                (t) => t.language === language,
                              ) ?? { language, description: '' },
                          ),
                        );
                        onChange(selectedLanguages);
                      }}
                      options={supportedLanguages.map((language) => ({
                        value: language,
                        label: formatLanguage(i18n.language, language),
                        disabled:
                          languages.includes(language) &&
                          language === defaultLanguage,
                      }))}
                      multiple
                      required
                      ref={ref}
                    />
                  );
                }}
              />

              <MultiLanguageInputContainer
                label={t('employees.details')}
                fields={translationsFields.map((field, i) => ({
                  id: field.id,
                  language: field.language,
                  component: (
                    <Controller
                      name={`translations.${i}.description`}
                      control={control}
                      render={({ field: { value, onChange, ref } }) => {
                        return (
                          <RichTextInput
                            value={value}
                            onChange={onChange}
                            ref={ref}
                          />
                        );
                      }}
                    />
                  ),
                }))}
              />
            </Stack>
          </Panel>
        </Stack>

        <Footer visible={isDirty}>
          <Stack direction="row" justifyContent="end" gap={2}>
            <Button
              label={t('common.cancel')}
              variant="neutral-outline"
              onClick={() => {
                reset(undefined, { keepDirty: false });
              }}
            />
            <Button
              label={t('common.save')}
              variant="primary"
              type="submit"
              disabled={isUpdating}
            />
          </Stack>
        </Footer>
      </form>

      <DataLossPrompt when={isDirty} />
    </>
  );
};
