import { Translations } from 'types/Common';
import { FrequencyUnit } from 'types/general';
import { z } from 'zod';
import { recognitionsFiltersSchema } from './recognitions.schemas';

export const recognitionApprovalStatuses = [
  'not_required',
  'pending',
  'approved',
  'rejected',
] as const;
export type RecognitionApprovalStatus =
  (typeof recognitionApprovalStatuses)[number];

export const recognitionTypes = [
  'companyToEmployee',
  'employeeToEmployee',
] as const;
export type RecognitionType = (typeof recognitionTypes)[number];

export const recognitionPoints = [
  'points',
  'thanks',
  'kudos',
  'bravo',
  'karma',
  'emoji_candy',
  'emoji_heart',
  'emoji_prayer',
  'emoji_coin',
  'emoji_star',
  'emoji_strawberry',
  'emoji_clap',
  'emoji_diamond',
  'emoji_unicorn',
  'emoji_panda',
  'emoji_rocket',
] as const;
export type RecognitionPoint = (typeof recognitionPoints)[number];

export const recognitionLimits = [
  'none',
  'perEmployee',
  'perDepartment',
  'perCompany',
] as const;
export type RecognitionLimit = (typeof recognitionLimits)[number];

export const expirationNotificationMethods = [
  'email',
  'appNotification',
] as const;
export type ExpirationNotificationMethod =
  (typeof expirationNotificationMethods)[number];

export interface Recognition {
  id: string;
  status: RecognitionApprovalStatus;
  type: RecognitionType;
  achievementTitle: string;
  pointsReceived: number;
  comment: string;
  employee: {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
  };
  sender: {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
  } | null;
  achievement: {
    id: string;
    name: string;
    title: string;
  } | null;
  createdAt: string;
}

export interface RecognitionsSettings {
  id: string;
  pointRewardPerRecognition: number;
  pointName: RecognitionPoint;
  currencyToPoint: number;
  useMoney: boolean;
  limit: RecognitionLimit;
  limitValue: number;
  limitPeriod: FrequencyUnit | 'none';
  expirationEnabled: boolean;
  expirationDay: number | null;
  expirationMonth: number | null;
  expirationNotificationInDays: number;
  expirationNotification: ExpirationNotificationMethod[];
  translations: Translations<{ id: string; description: string }>;
}

export type RecognitionsFilters = z.infer<typeof recognitionsFiltersSchema>;
