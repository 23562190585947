import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import {
  IRecognitionRecipient,
  IRecognitionRecipientGiver,
} from 'types/income';
import { exportXlsxConfig } from 'utils/general';
import {
  RecognitionRecipientGiversFilters,
  RecognitionRecipientsFilters,
} from './recognition-recipients.types';

const getFilters = (filters: Partial<RecognitionRecipientsFilters>) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  sort: filters.sort
    ? `${filters.sort.order === 'desc' ? '-' : ''}${filters.sort.columnKey}`
    : undefined,
  filter: {
    employeeName: filters.search || undefined,
    dateFrom: filters.date?.from,
    dateTo: filters.date?.to,
  },
});

interface GetRecipients {
  filters: Partial<RecognitionRecipientsFilters>;
}

interface GetRecipientGivers {
  employeeId: string;
  filters: Partial<RecognitionRecipientGiversFilters>;
}

interface ExportXls {
  filters: Partial<RecognitionRecipientsFilters>;
}

export const loaders = {
  getRecipients: ({ filters }: GetRecipients) => {
    return api<{
      count: number;
      page: number;
      pageSize: number;
      employees: IRecognitionRecipient[];
    }>({
      url: Endpoints.clientAdmin.recognitions.recipients.root,
      params: {
        ...getFilters(filters),
      },
    });
  },
  getRecipientGivers: ({ employeeId, filters }: GetRecipientGivers) => {
    return api<TableDataResponse<IRecognitionRecipientGiver>>({
      url: Endpoints.clientAdmin.recognitions.recipients.byId.replace(
        ':employeeId',
        employeeId,
      ),
      params: {
        filter: {
          dateFrom: filters.date?.from,
          dateTo: filters.date?.to,
        },
      },
    });
  },
  exportXls: ({ filters }: ExportXls) => {
    const { page, pageSize, sort, ...restFilters } = getFilters(filters);
    return api({
      url: Endpoints.clientAdmin.recognitions.recipients.export.xls,
      params: {
        ...restFilters,
      },
      ...exportXlsxConfig,
    });
  },
};
